import React from 'react';
import PropTypes from 'prop-types';

const AppLogo = ({ showText, imgStyles }) => (
    <div className="flex" style={{ padding: '10px 0' }}>

        {showText ? (
            <a className="flex" style={{ alignItems: 'center' }} id="logo" href="/">
                <img
                    style={imgStyles}
                    alt="logo"
                    src="/assets/logo-eshmoun.png"
                />
            </a>

        ) : (
            <a className="flex" style={{ alignItems: 'baseline', paddingLeft: 0 }} id="logo" href="/">
                <img
                    style={{ ...imgStyles, marginLeft: 20 }}
                    alt="logo"
                    src="/assets/eshmoun-fav.gif"
                />
            </a>
        )}
    </div>
);

AppLogo.propTypes = {
    showText: PropTypes.bool,
    imgStyles: PropTypes.object,
};

AppLogo.defaultProps = {
    showText: true,
    imgStyles: {},
};

export default AppLogo;
