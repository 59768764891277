/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import DynamicField from '../../../components/DynamicField';

const Treatment = ({ form, values }) => (
    <Form
        style={{ marginTop: 30 }}
        form={form}
    >
        <div style={{ padding: '0 34px', marginRight: '10%' }}>
            {[
                'lastConsultation',
                'firstJakaviPrescription',
                'posology',
                'therapyEducation',
                'posologyEducation',
                'diseaseEducation',
                'formMPN10Education',
                'fillForm',
                'returnMPN10',
                'doseAdjustment',
            ].map(k => (
                <DynamicField key={k} field={values.treatment[k]} />
            ))}
        </div>
    </Form>
);

Treatment.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};

export default Treatment;
