import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import PropTypes from 'prop-types';
import {
    ContactsOutlined,
    CarryOutOutlined,
    ExceptionOutlined,
    PhoneOutlined,
    QuestionCircleOutlined,
    CheckCircleOutlined,
    IssuesCloseOutlined,
    SecurityScanOutlined,
} from '@ant-design/icons';
import AppLogo from './AppLogo';
import { navigateTo } from '../../history';

const { SubMenu } = Menu;
const { Sider } = Layout;

const SideBar = ({ selectedItem, isCallCenter }) => {
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 600 || false);
    return (
        <Sider
            width={230}
            collapsible
            collapsed={collapsed}
            onCollapse={() => {
                setCollapsed(!collapsed);
            }}
            className="layout-bg"
        >
            <AppLogo showText={!collapsed} />
            <Menu
                theme="light"
                mode="inline"
                selectedKeys={[selectedItem]}
                className="side-bar-menu"
            >

                <Menu.Item
                    key="/patients"
                    onClick={() => navigateTo('/patients')}
                    icon={<ContactsOutlined />}
                >
                    Patients
                </Menu.Item>

                <SubMenu
                    title="Appels Patients"
                    key="calls"
                    icon={<CarryOutOutlined />}
                >
                    <Menu.Item
                        className="menu-violette"
                        key="/red"
                        onClick={() => navigateTo('/red')}
                    >
                        {' '}
                        Carte violette
                        {' '}
                    </Menu.Item>
                    <Menu.Item
                        className="menu-bleue"
                        key="/blue"
                        onClick={() => navigateTo('/blue')}
                    >
                        {' '}
                        Carte bleue
                        {' '}
                    </Menu.Item>
                    <Menu.Item
                        className="menu-orange"
                        key="/orange"
                        onClick={() => navigateTo('/orange')}
                    >
                        {' '}
                        Carte orange
                    </Menu.Item>
                </SubMenu>
                {isCallCenter ? (
                    <Menu.Item
                        key="/incoming"
                        onClick={() => navigateTo('/incoming')}
                        icon={<PhoneOutlined />}
                    >
                        Appels entrants
                    </Menu.Item>
                ) : null}
                <SubMenu title="Queries" key="queries" icon={<SecurityScanOutlined />}>
                    <Menu.Item
                        key="/open"
                        onClick={() => navigateTo('/open')}
                        icon={<QuestionCircleOutlined />}
                    >
                        {' '}
                        En attente
                        {' '}
                    </Menu.Item>
                    <Menu.Item
                        key="/answered"
                        onClick={() => navigateTo('/answered')}
                        icon={<CheckCircleOutlined />}
                    >
                        {' '}
                        Traitées
                        {' '}
                    </Menu.Item>
                    <Menu.Item
                        key="/closed"
                        onClick={() => navigateTo('/closed')}
                        icon={<IssuesCloseOutlined />}
                    >
                        {' '}
                        Fermées
                    </Menu.Item>
                </SubMenu>
                <Menu.Item
                    key="/adverse"
                    onClick={() => navigateTo('/adverse')}
                    icon={<ExceptionOutlined />}
                >
                    Evenements indésirables
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

SideBar.propTypes = {
    selectedItem: PropTypes.string.isRequired,
    isCallCenter: PropTypes.bool.isRequired,
};

const stateToProps = state => ({
    isCallCenter: state.auth.user.type !== 2,
});

export default connect(stateToProps)(SideBar);
