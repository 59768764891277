/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React from 'react';
import DynamicField from '../components/DynamicField';

export const getFullName = user => `${user.lastName} ${user.firstName}`;
export const getFullNameDynamicF = user => `${user.lastName.value} ${user.firstName.value}`;
export const getUserInitials = user => `${user.lastName[0]} ${user.firstName[0]}`;
export const getFullNameInversed = user => `${user.firstName} ${user.lastName}`;

export const formatDateTimeFromIso = date => (date
    ? DateTime.fromISO(date)
        .setLocale('fr')
        .toLocaleString(DateTime.DATETIME_MED)
    : '');

export const formatTimeFromIso = date => (date
    ? DateTime.fromISO(date)
        .setLocale('fr')
        .toLocaleString(DateTime.TIME_24_SIMPLE)
    : '');

export const dateTimeFromTimestamp = date => (date
    ? DateTime.fromMillis(date)
        .setLocale('fr')
        .toLocaleString(DateTime.TIME_24_SIMPLE)
    : '');

// eslint-disable-next-line no-mixed-operators
export const calculateBMI = (weight, height) => (weight / (height / 100) ** 2).toFixed(2);

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTimeFromISODate = isoDate => isoDate.substr(11, 5);

export const structureFields = (initialValues, obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    const res = {};
    keys.forEach((key, i) => {
        if (initialValues[key]) {
            if (initialValues[key].extraFields) {
                res[key] = {
                    ...initialValues[key],
                    value: values[i].value,
                    sdv: false,
                    readOnly: true,
                    query: null,
                };
                initialValues[key].extraFields.fields.forEach((f, index) => {
                    res[key].extraFields.fields[index] = {
                        ...f,
                        value: values[i][f.name[1]],
                        sdv: false,
                        readOnly: true,
                        query: null,
                    };
                });
            } else {
                res[key] = {
                    ...initialValues[key],
                    value: values[i],
                    sdv: false,
                    readOnly: true,
                    query: null,
                };
            }
        } else {
            res[key] = {
                value: values[i],
                sdv: false,
                readOnly: true,
                query: null,
            };
        }
    });
    return res;
};

export const mapFieldsToTree = (fieldFamily, call, onUpdate) => {
    const res = [];
    Object.keys(fieldFamily).forEach((k) => {
        if (fieldFamily[k].value) {
            return res.push({
                title: (
                    <DynamicField
                        field={fieldFamily[k]}
                        key={fieldFamily.name}
                        withActions={!!call}
                        onUpdate={onUpdate}
                        call={call}
                        fieldFamily={fieldFamily.name}
                    />
                ),

                key: k,
            });
        }
    });
    return res;
};

export const getExtraFieldsValues = (field) => {
    const vals = {
        value: field.value,
    };
    field.extraFields.fields.forEach((item) => { vals[item.name[1]] = item.value; });
    return vals;
};
