export default {
    firstName: {
        name: 'firstName',
        label: 'Prénom',
        rules: ['required'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
    },
    lastName: {
        name: 'lastName',
        label: 'Nom',
        rules: ['required'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
    },
    address: {
        name: 'address',
        label: 'Adresse',
        rules: ['required'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
    },
    city: {
        name: 'city',
        label: 'Ville',
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,

    },
    gender: {
        name: 'gender',
        label: 'Sexe',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Homme', value: 'Homme' },
            { label: 'Femme', value: 'Femme' },
        ],
        strongLabel: true,

    },
    birthDate: {
        name: 'birthDate',
        label: 'Date de naissance',
        rules: ['required', 'validDate'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        placeholder: 'e.g: 01/01/2000',
        strongLabel: true,

    },
    phoneNumber: {
        name: 'phoneNumber',
        label: 'Téléphone',
        rules: ['required', 'phoneNumber'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,

    },
    phoneNumber2: {
        name: 'phoneNumber2',
        label: 'Téléphone 2',
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,

    },
    email: {
        name: 'email',
        label: 'Adresse email',
        rules: ['email'],
        type: 'input',
        inputStyle: { width: 'min-content' },
        strongLabel: true,

    },
    cardColor: {
        name: 'cardColor',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Violette', value: 'Violette' },
            { label: 'Bleu', value: 'Bleu' },
            { label: 'Orange', value: 'Orange' },
        ],
        strongLabel: true,

    },
    consent: {
        signed: {
            name: 'signed',
            rules: ['required'],
            label: 'Signature du consentement',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            strongLabel: true,

        },
        consentDate: {
            name: 'consentDate',
            label: 'Date de signature du consentement',
            rules: ['validDate', 'required'],
            type: 'input',
            inputStyle: { width: 'min-content' },
            placeholder: 'e.g: 01/01/2000',
            strongLabel: true,

        },
        isReachable: {
            name: 'isReachable',
            rules: ['required'],
            label: 'Le patient est-il joignable',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
    },
    laboratoryDiagnosis: {
        name: 'laboratoryDiagnosis',
        rules: ['required'],
        label: 'Diagnostic au laboratoire',
        type: 'radio',
        radioFields: [
            { label: 'Institut Pasteur de Tunis', value: 'Institut Pasteur de Tunis' },
            { label: 'Aziza Othmana', value: 'Aziza Othmana' },
            { label: 'Farhat Hached', value: 'Farhat Hached' },
            { label: 'Hedi Chaker', value: 'Hedi Chaker' },
        ],
    },

    doctor: {
        firstName: {
            name: 'firstName',
            label: 'Prénom',
            inputStyle: { width: 'min-content' },
            type: 'input',
            strongLabel: true,

        },
        lastName: {
            name: 'lastName',
            label: 'Nom',
            rules: ['required'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            strongLabel: true,

        },
        place: {
            name: 'place',
            rules: ['required'],
            label: 'Établissement',
            type: 'radio',
            radioFields: [
                { label: 'Hôpital', value: 'Hôpital' },
                { label: 'Cabinet privé', value: 'Cabinet privé' },
            ],
            strongLabel: true,
        },
        address: {
            name: 'address',
            label: 'Adresse professionnelle',
            rules: ['required'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            strongLabel: true,
        },
        phoneNumber: {
            name: 'phoneNumber',
            label: 'Téléphone',
            rules: ['required', 'phoneNumber'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            strongLabel: true,
        },
        email: {
            name: 'email',
            label: 'Adresse email',
            rules: ['email'],
            type: 'input',
            inputStyle: { width: 'min-content' },
            strongLabel: true,
        },
    },
    treatment: {
        lastConsultation: {
            name: 'lastConsultation',
            label: 'Date de la dernière consultation',
            rules: ['required', 'validDate'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            placeholder: 'e.g: 01/01/2000',
            sdv: false,
            strongLabel: true,
        },
        firstJakaviPrescription: {
            name: 'firstJakaviPrescription',
            label: 'Date de la 1ère prescription de Jakavi',
            rules: ['required', 'validDate'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            placeholder: 'e.g: 01/01/2000',
            sdv: false,
            strongLabel: true,
        },
        posology: {
            name: 'posology',
            label: 'Posologie prescrite',
            type: 'radio',
            radioFields: [
                { label: '5mgx2/jour', value: '5mgx2/jour' },
                { label: '10mgx2/jour', value: '10mgx2/jour' },
                { label: '15mgx2/jour', value: '15mgx2/jour' },
                { label: '20mgx2/jour', value: '20mgx2/jour' },
                { label: '25mgx2/jour', value: '25mgx2/jour' },
            ],
            sdv: false,
            strongLabel: true,
        },
        therapyEducation: {
            name: 'therapyEducation',
            rules: ['required'],
            label: 'Education thérapeutique rappelée',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,
        },
        posologyEducation: {
            name: 'posologyEducation',
            rules: ['required'],
            label: 'Posologie comprise et bien appliquée',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,
        },
        formMPN10Education: {
            name: 'formMPN10Education',
            rules: ['required'],
            label: 'Explication du remplissage du Formulaire MPN10',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,
        },
        fillForm: {
            name: 'fillForm',
            rules: ['required'],
            label: 'Remplissage du formulaire par la même personne à chaque fois rappelé',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,
        },
        returnMPN10: {
            name: 'returnMPN10',
            rules: ['required'],
            label: 'Retour du formulaire MPN10 au médecin rappelé',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,
        },
        doseAdjustment: {
            name: ['doseAdjustment', 'value'],
            rules: ['required'],
            label: 'Avez-vous déjà eu un ajustement de dose de Jakavi ?',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            strongLabel: true,
            extraFields: {
                fields: [
                    {
                        name: ['doseAdjustment', 'precedentPosology'],
                        label: 'Dose precedente (mg/jour)',
                        rules: ['required'],
                        type: 'input',
                        sdv: false,
                        strongLabel: true,
                    },
                    {
                        name: ['doseAdjustment', 'precedentDuration'],
                        label: 'Durée (semaine)',
                        rules: ['required'],
                        type: 'input',
                        sdv: false,
                        strongLabel: true,

                    },
                ],
                displayValue: 'Oui',
            },
        },

    },
    cnam: {
        applicationForm: {
            name: 'applicationForm',
            rules: ['required'],
            label: 'Explication de la préparation du dossier de la CNAM  et des différentres étapes de suivi de l’avancement du remboursement',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        affiliation: {
            name: 'affiliation',
            rules: ['required'],
            label: 'Affiliation à la CNAM ',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        AP1form: {
            name: 'AP1form',
            rules: ['required'],
            label: 'Remplissage formulaire AP1 par le médecin',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        letter: {
            name: 'letter',
            rules: ['required'],
            label: 'Lettre explicative confidentielle signée par le médecin traitant',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        prescription: {
            name: 'prescription',
            rules: ['required'],
            label: 'Prescription médicale signée par le médecin traitant',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        nationalCard: {
            name: 'nationalCard',
            rules: ['required'],
            label: 'Copie de la carte d’identité nationale du patient',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        completeRecord: {
            name: 'completeRecord',
            rules: ['required'],
            label: 'Dossier complet relatif à l’état de santé du patient',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        agreement: {
            name: 'agreement',
            rules: ['required'],
            label: 'Accord de prise en charge Jakavi',
            type: 'radio',
            radioFields: [
                {
                    label: 'Oui',
                    value: 'Oui',
                },
                { label: 'Non', value: 'Non' },
                { label: 'En attente', value: 'En attente' },
            ],
            sdv: false,
            strongLabel: true,

        },
        receivedDrug: {
            name: ['receivedDrug', 'value'],
            rules: ['required'],
            label: 'Médicament reçu de la polyclinique CNSS',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            strongLabel: true,

            sdv: false,
            extraFields: {
                fields: [
                    {
                        name: ['receivedDrug', 'posology'],
                        rules: ['required'],
                        label: 'Dosage (mg)',
                        type: 'radio',
                        radioFields: [
                            { label: '5', value: 5 },
                            { label: '15', value: 15 },
                            { label: '20', value: 20 },
                        ],
                        sdv: false,
                        strongLabel: true,

                    },
                    {
                        name: ['receivedDrug', 'packetNumber'],
                        label: 'Nombre de boites',
                        rules: ['required'],
                        type: 'input',
                        sdv: false,
                        strongLabel: true,

                    },
                    {
                        name: ['receivedDrug', 'duration'],
                        label: 'Durée (semaine)',
                        rules: ['required'],
                        type: 'input',
                        sdv: false,
                        strongLabel: true,

                    },

                ],
                displayValue: 'Oui',
            },
        },
        comprehension: {
            name: 'comprehension',
            rules: ['required'],
            label: 'Compréhension du dossier CNAM et de ses étapes',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        doseChangement: {
            name: 'doseChangement',
            rules: ['required'],
            label: 'Accord de changement de dose par la CNAM',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },

    },
    nextCallDate: {
        name: 'nextCallDate',
        label: 'Date du prochain appel',
        rules: ['validDate'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        placeholder: 'e.g: 01/01/2000',
        sdv: false,
        strongLabel: true,

    },
    workshop: {
        invitation: {
            name: 'invitation',
            label: 'Invitation au workshop régional',
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
            sdv: false,
            strongLabel: true,

        },
        date: {
            name: 'date',
            label: 'Date',
            rules: ['validDate'],
            inputStyle: { width: 'min-content' },
            type: 'input',
            placeholder: 'e.g: 01/01/2000',
            sdv: false,
            strongLabel: true,

        },
        place: {
            name: 'place',
            label: 'Lieu',
            inputStyle: { width: 'min-content' },
            type: 'input',
            sdv: false,
            strongLabel: true,

        },
    },
    initialConsent: {
        name: 'initialConsent',
        rules: ['required'],
        label: 'Signature du consentement',
        type: 'radio',
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
        strongLabel: true,

    },

};
