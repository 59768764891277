/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button, Card, Form, Popconfirm,
} from 'antd';
import { getFullNameDynamicF } from '../../../helpers/functions';
import HeadBreadCrumb from '../../../components/HeadBreadCrumb';
import { updatePatientAsync } from '../../../redux/patients/actions';
import AddRedForm from '../rouge/add';
import AddBlueForm from '../bleue/add';
import CardPicker from '../../../components/CardsPicker';


const AddCall = ({
    selectedPatient, handleGoBack, updatePatientAsync,
}) => {
    window.onbeforeunload = function () { return 'Etes vous sûr(e) de vouloir quitter ?'; };
    const [showRedAdd, setShowRedAdd] = useState(false);
    const [showBlueAdd, setShowBlueAdd] = useState(false);
    const [selectedCard, selectCard] = useState();
    const [form] = Form.useForm();

    const handleSelectCard = (cardColor) => {
        form.setFieldsValue({ cardColor });
        selectCard(cardColor);
    };

    const handleUpdate = () => {
        updatePatientAsync(selectedPatient._id, {
            cardColor: selectedCard,
        }, true);
        if (selectedCard === 'Violette') {
            setShowRedAdd(true);
        } else {
            setShowBlueAdd(true);
        }
    };


    if (showRedAdd) {
        return <AddRedForm selectedPatient={selectedPatient} handleGoBack={handleGoBack} />;
    }

    if (showBlueAdd) {
        return <AddBlueForm selectedPatient={selectedPatient} handleGoBack={handleGoBack} />;
    }
    return (
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Appels patient"
                secondSectionName={selectedPatient ? getFullNameDynamicF(selectedPatient) : 'Patient'}
                thirdSectionName="Ajout d'un appel"
                handleGoBack={handleGoBack}
            />
            <Card>
                <div className="flex p-top-meidum" style={{ justifyContent: 'space-around' }}>
                    <Form form={form}>
                        <CardPicker onSelect={handleSelectCard} showOrange={false} />
                    </Form>
                </div>
                <div className="flex m-auto">
                    <Popconfirm
                        okText="Oui"
                        cancelText="Annuler"
                        disabled={!selectedCard}
                        onConfirm={handleUpdate}
                        title="Êtes vous sure de votre choix?">
                        <Button
                            type="primary"
                            className="m-top-medium m-bottom-medium center-button"
                            disabled={!selectedCard}
                        >
                            Valider
                        </Button>
                    </Popconfirm>
                </div>
            </Card>
        </div>
    );
};

AddCall.propTypes = {
    selectedPatient: PropTypes.object.isRequired,
    handleGoBack: PropTypes.func.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
};


// const stateToProps = state => ({
// });

const dispatchToProps = dispatch => bindActionCreators(
    {
        updatePatientAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(AddCall);
