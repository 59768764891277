/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
    Spin,
    Table,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from 'antd/lib/typography/Title';
import {
    getAllQueriesAsync,
    setQueryList,
    selectQueryAsync,
} from '../../../redux/query/actions';
import {
    formatDateTimeFromIso,
} from '../../../helpers/functions';


const QueryList = ({
    user,
    contentIsLoading,
    queryList,
    getAllQueriesAsync,
    setQueryList,
}) => {
    useEffect(() => {
        if (user) {
            const query = user.type === 1 ? '?status=closed&isCallCenterAgent=true' : '?status=closed';
            getAllQueriesAsync(query);
        }
        return () => {
            setQueryList([]);
        };
    }, [user]);

    const columns = [

        {
            title: <b>Nature</b>,
            key: 'queryName',
            render: (_, record) => <p>{record.field.label}</p>,
        },
        {
            title: <b>Patient</b>,
            key: 'name',
            render: (_, record) => (
                <p>
                    {`${
                        record.patient.firstName ? record.patient.firstName.value : ''
                    } ${record.patient.lastName ? record.patient.lastName.value : ''}`}

                </p>
            ),
        },
        {
            title: <b>Date de l&apos;appel</b>,
            key: 'call',
            render: (_, record) => <p>{formatDateTimeFromIso(record.call.createdAt)}</p>,
        },
        {
            title: <b> Date d&apos;envoi</b>,
            key: 'date',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },

    ];


    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                <Title level={5}>
                    Queries fermées
                </Title>
                <Table
                    className="m-top-medium"
                    columns={columns}
                    dataSource={queryList}
                    locale={{ emptyText: 'Aucune Query' }}
                />

            </div>
        </Spin>
    );
};

QueryList.propTypes = {
    contentIsLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    setQueryList: PropTypes.array.isRequired,
    getAllQueriesAsync: PropTypes.func.isRequired,
    queryList: PropTypes.array.isRequired,


};

QueryList.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    queryList: state.queries.queryList,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllQueriesAsync,
        selectQueryAsync,
        setQueryList,

    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(QueryList);
