/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import * as PatientsAPI from '../../../api/patient';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_PATIENTS_LIST = 'SET_PATIENTS_LIST';
export const ADD_PATIENT = 'ADD_PATIENT';
export const SELECT_PATIENT = 'SELECT_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';

export const setPatientsList = value => ({
    type: SET_PATIENTS_LIST,
    payload: value,
});

export const selectPatient = value => ({
    type: SELECT_PATIENT,
    payload: value,
});


export const removePatient = value => ({
    type: REMOVE_PATIENT,
    payload: value,
});


export const addPatient = value => ({
    type: ADD_PATIENT,
    payload: value,
});

export const updatePatient = value => ({
    type: UPDATE_PATIENT,
    payload: value,
});


export const getAllOnboardedPatientsAsync = query => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getAllOnboarded(query);
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const getAllPatientsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getAll();
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const getPendingPatientsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getPending();
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createPatientAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.create(body);
        dispatch(addPatient(res.data));
        message.success('Patient ajouté avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectPatientAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getById(id);
        dispatch(selectPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updatePatientAsync = (id, body, removeFromList) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.update(id, body);
        dispatch(selectPatient(res.data));
        if (body.consent || removeFromList) {
            dispatch(removePatient(res.data._id));
            message.success('Modification effectuée avec succès');
        } else {
            dispatch(updatePatient(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
