/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Menu, Tree } from 'antd';
import { useDispatch } from 'react-redux';
import { prepareTree } from '../../../helpers/callHistory';
import {
    formatDateTimeFromIso,
    getExtraFieldsValues,
} from '../../../helpers/functions';
import { updateCallAsync } from '../../../redux/call/actions';
import QueryModal from '../../../components/QueryModal';

const DynamicForm = (props) => {
    const [treatmentForm] = Form.useForm();
    const [cnamForm] = Form.useForm();
    const [workshopJakaviForm] = Form.useForm();
    const [workshopDiseaseForm] = Form.useForm();
    const [patientForm] = Form.useForm();
    const [doctorForm] = Form.useForm();
    const [descriptionForm] = Form.useForm();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [openQueryModal, setOpenQueryModal] = useState(false);
    const [selectedField, selectField] = useState();

    const { call } = props;
    const dispatch = useDispatch();
    console.log('here', props);
    const {
        patient,
        treatment,
        workshopDisease,
        workshopJakavi,
        cnam,
        description,
        ...rest
    } = call;

    const [callData, setCallData] = useState({
        patient,
        cnam,
        treatment,
        workshopDisease,
        workshopJakavi,
        doctor: patient.doctor,
        description,
    });

    const mapKeyToForm = {
        treatment: treatmentForm,
        cnam: cnamForm,
        workshopJakavi: workshopJakaviForm,
        workshopDisease: workshopDiseaseForm,
        patient: patientForm,
        doctor: doctorForm,
        description: descriptionForm,
    };

    const prepareUpdateBody = (
        fieldFamily,
        fieldName,
        prop,
        value,
        extraFieldIndex,
    ) => {
        let updateBody;
        if (!isNaN(extraFieldIndex)) {
            // means we are updating an extraField
            const extraFields = callData[fieldFamily][fieldName].extraFields.fields;
            extraFields[extraFieldIndex] = {
                ...extraFields[extraFieldIndex],
                [prop]:
          prop === 'value'
              ? value[extraFields[extraFieldIndex].name[1]]
              : value,
            };
            if (prop === 'value') {
                extraFields[extraFieldIndex].readOnly = true;
            }
            updateBody = {
                field: {
                    ...callData[fieldFamily][fieldName],
                    extraFields: {
                        ...callData[fieldFamily][fieldName].extraFields,
                        fields: extraFields,
                    },
                },
                fieldFamily,
                extraFieldIndex,
            };
        } else {
            updateBody = {
                field: {
                    ...callData[fieldFamily][fieldName],
                    [prop]: value,
                },
                fieldFamily,
            };
            if (prop === 'value') {
                updateBody.field.readOnly = true;
            }
        }
        return updateBody;
    };

    const changeProp = (fieldFamily, fieldName, prop, value, extraFieldIndex) => {
        let newCallData;
        if (!isNaN(extraFieldIndex)) {
            const extraFields = callData[fieldFamily][fieldName].extraFields.fields;
            extraFields[extraFieldIndex] = {
                ...extraFields[extraFieldIndex],
                [prop]:
          prop === 'value'
              ? value[extraFields[extraFieldIndex].name[1]]
              : value,
            };

            if (prop === 'value') {
                extraFields[extraFieldIndex].readOnly = true;
            }
            newCallData = {
                ...callData,
                [fieldFamily]: {
                    ...callData[fieldFamily],
                    [fieldName]: {
                        ...callData[fieldFamily][fieldName],
                        extraFields: {
                            ...callData[fieldFamily][fieldName].extraFields,
                            fields: extraFields,
                        },
                    },
                },
            };
        } else {
            newCallData = {
                ...callData,
                [fieldFamily]: {
                    ...callData[fieldFamily],
                    [fieldName]: {
                        ...callData[fieldFamily][fieldName],
                        [prop]: value,
                    },
                },
            };
            if (prop === 'value') {
                newCallData[fieldFamily][fieldName].readOnly = true;
            }
        }
        setCallData(newCallData);
    };

    const handleUpdateField = (
        fieldFamily,
        fieldNames,
        prop,
        value,
        extraFieldIndex,
    ) => {
        const fieldName = Array.isArray(fieldNames) ? fieldNames[0] : fieldNames;

        switch (prop) {
            case 'value':
                const val = mapKeyToForm[fieldFamily].getFieldValue(fieldName);
                changeProp(fieldFamily, fieldName, 'value', val, extraFieldIndex);
                const body = prepareUpdateBody(
                    fieldFamily,
                    fieldName,
                    prop,
                    val,
                    extraFieldIndex,
                );
                dispatch(updateCallAsync(call._id, body));
                break;
            case 'cancel':
                if (callData[fieldFamily][fieldName].extraFields) {
                    mapKeyToForm[fieldFamily].setFieldsValue({
                        [fieldName]: getExtraFieldsValues(callData[fieldFamily][fieldName]),
                    });
                } else {
                    mapKeyToForm[fieldFamily].setFieldsValue({
                        [fieldName]: callData[fieldFamily][fieldName].value,
                    });
                }
                changeProp(fieldFamily, fieldName, 'readOnly', true, extraFieldIndex);
                break;
            case 'readOnly':
                changeProp(fieldFamily, fieldName, prop, value, extraFieldIndex);
                break;
            case 'query':
                if (!isNaN(extraFieldIndex)) {
                    selectField({ ...call[fieldFamily][fieldName].extraFields.fields[extraFieldIndex], fieldFamily });
                } else if (fieldFamily === 'doctor') {
                    selectField({ ...call.patient.doctor[fieldName], fieldFamily });
                } else {
                    selectField({ ...call[fieldFamily][fieldName], fieldFamily });
                }
                setOpenQueryModal(true);
                break;

            default:
                changeProp(fieldFamily, fieldName, prop, value, extraFieldIndex);
                const updateBody = prepareUpdateBody(
                    fieldFamily,
                    fieldName,
                    prop,
                    value,
                    extraFieldIndex,
                );
                dispatch(updateCallAsync(call._id, updateBody));
        }
    };

    const fieldFamilies = Object.keys(callData);
    const mapFieldValues = () => {
    // eslint-disable-next-line array-callback-return
        fieldFamilies.forEach((fieldFamilyName) => {
            const family = callData[fieldFamilyName];
            if (family) {
                const keys = Object.keys(family);
                keys.forEach((key) => {
                    if (family[key].extraFields) {
                        mapKeyToForm[fieldFamilyName].setFieldsValue({
                            [key]: getExtraFieldsValues(family[key]),
                        });
                    } else {
                        mapKeyToForm[fieldFamilyName].setFieldsValue({
                            [key]: family[key].value,
                        });
                    }
                });
            }
        });
    };

    useEffect(() => {
        mapFieldValues();
    }, []);
    return (
        <Menu mode="inline" className="call-history-menu">
            <Menu.SubMenu title={formatDateTimeFromIso(rest.createdAt)}>
                {fieldFamilies.map(
                    fieldFamily => callData[fieldFamily] && (
                        <Menu.Item key={fieldFamily}>
                            <Form key={fieldFamily} form={mapKeyToForm[fieldFamily]}>
                                <Tree
                                    key={fieldFamily}
                                    expandedKeys={expandedKeys}
                                    onExpand={a => setExpandedKeys(a)}
                                    treeData={prepareTree(
                                        { name: fieldFamily, ...callData[fieldFamily] },
                                        rest,
                                        handleUpdateField,
                                    )}
                                />
                            </Form>
                            {fieldFamily === 'description' ? <div style={{marginLeft:'30px',color:"black", whiteSpace : 'normal' }}> {description}  </div> : ''}
                        </Menu.Item>
                    ),
                )}
                <QueryModal
                    call={call}
                    open={openQueryModal}
                    field={selectedField}
                    handleClose={() => setOpenQueryModal(false)}
                />
            </Menu.SubMenu>
        </Menu>
    );
};

DynamicForm.propTypes = {
    call: PropTypes.object.isRequired,
};

export default DynamicForm;
