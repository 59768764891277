export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_CONTENT_LOADING = 'SET_CONTENT_LOADING';
export const SET_DIALOG_LOADING = 'SET_DIALOG_LOADING';
export const SET_BUTTON_LOADING = 'SET_BUTTON_LOADING';

export const setAppIsLoading = value => ({
    type: SET_APP_LOADING,
    payload: value,
});

export const setContentIsLoading = value => ({
    type: SET_CONTENT_LOADING,
    payload: value,
});

export const setDialogIsLoading = value => ({
    type: SET_DIALOG_LOADING,
    payload: value,
});


export const setButtonIsLoading = value => ({
    type: SET_BUTTON_LOADING,
    payload: value,
});
