/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-promise-reject-errors */
import { DateTime } from 'luxon';

export default {
    required: {
        required: true,
        message: 'Ce champ est requis.',
    },
    number: {
        validator: (_, value) => (!isNaN(value)
            ? Promise.resolve()
            : Promise.reject(
                'Please provide a valid phone number.',
            )),
    },
    email: {
        type: 'email',
        message: 'Veuillez entrer une adresse e-mail valide.',
    },
    time: {
        validator: (_, value) => ((value && !DateTime.fromFormat(value, 'HH:mm').invalid) || !value || value.trim().length === 0
            ? Promise.resolve()
        // eslint-disable-next-line prefer-promise-reject-errors
            : Promise.reject(new Error('Temps invalide'))),
    },
    validDate: {
        validator: (_, value) => ((value && !DateTime.fromFormat(value, 'dd/MM/yyyy').invalid) || !value || value.trim().length === 0
            ? Promise.resolve()
        // eslint-disable-next-line prefer-promise-reject-errors
            : Promise.reject(new Error('Date invalide'))),
    },
    phoneNumber: {
        validator: (_, value) => (!isNaN(value) && value.length === 8
            ? Promise.resolve()
            : Promise.reject(
                'Veuillez indiquer un numéro de téléphone valide.',
            )),
    },

};
