import React, { useState } from 'react';
import {
    Form, Input, InputNumber, Radio, Descriptions, Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../helpers/formRules';
import TimePicker from '../TimePicker';
import FieldActions from '../FieldActions';

const format = 'HH:mm';

// eslint-disable-next-line max-len
const prepareFieldLabel = (isStrongLabel, label) => (isStrongLabel ? <strong>{label}</strong> : label);

export const mapRules = (rules) => {
    const res = [];
    if (rules) {
        rules.map(rule => (FORM_VALIDATORS[rule] ? res.push(FORM_VALIDATORS[rule]) : null));
    }
    return res;
};

const DynamicField = ({
    field, withActions, call, onUpdate, fieldFamily,
}) => {
    if (!field) return <div />;

    const displayExtra = field.value
    && field.value
    && field.extraFields
    && field.extraFields.displayValue === field.value;
    const [showExtraFields, setShowExtraFields] = useState(displayExtra);

    const handleChange = (e) => {
        if (e.target.value === field.extraFields.displayValue) {
            setShowExtraFields(true);
        } else setShowExtraFields(false);
    };

    const renderField = (fieldToRender) => {
        switch (fieldToRender.type) {
            case 'input':
                return (
                    <Form.Item
                        style={{ ...fieldToRender.style }}
                        name={fieldToRender.name}
                        rules={mapRules(fieldToRender.rules)}
                    >
                        <Input
                            placeholder={fieldToRender.placeholder}
                            value={fieldToRender.value}
                            onChange={fieldToRender.extraFields ? handleChange : null}
                            style={fieldToRender.inputStyle}
                            readOnly={fieldToRender.readOnly}
                            disabled={fieldToRender.frozen}
                        />
                    </Form.Item>
                );
            case 'inputNumber':
                return (
                    <Form.Item
                        style={{ ...fieldToRender.style }}
                        name={fieldToRender.name}
                        rules={mapRules(fieldToRender.rules)}
                    >
                        <InputNumber
                            placeholder={fieldToRender.placeholder}
                            value={fieldToRender.value}
                            style={fieldToRender.inputNumberStyle}
                            onChange={fieldToRender.extraFields ? handleChange : null}
                            min={fieldToRender.minNumber}
                            max={fieldToRender.maxNumber}
                            readOnly={fieldToRender.readOnly}
                            disabled={fieldToRender.frozen}
                        />
                    </Form.Item>
                );
            case 'radio':
                return (
                    <Form.Item
                        style={{ ...fieldToRender.style }}
                        name={fieldToRender.name}
                        rules={mapRules(fieldToRender.rules)}
                    >
                        <Radio.Group
                            disabled={fieldToRender.readOnly || fieldToRender.frozen}
                            onChange={fieldToRender.extraFields ? handleChange : null}
                            value={fieldToRender.value}
                            className="flex flex-wrap"
                        >
                            {fieldToRender.radioFields.map((rf, key) => (
                                <Radio value={rf.value} key={key.toString()}>
                                    {rf.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                );
            case 'checkbox':
                return (
                    <Form.Item
                        style={{ ...fieldToRender.style }}
                        name={fieldToRender.name}
                        rules={mapRules(fieldToRender.rules)}
                    >
                        <Checkbox.Group
                            disabled={fieldToRender.readOnly || fieldToRender.frozen}
                            onChange={fieldToRender.extraFields ? handleChange : null}
                            value={fieldToRender.value}
                        >
                            {fieldToRender.checkboxFields.map((cf, key) => (
                                <Checkbox value={cf.value} key={key.toString()}>
                                    {cf.label}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                );
            case 'datetime':
                return (
                    <Form.Item style={{ ...fieldToRender.style }}>
                        <Input.Group style={{ display: 'flex' }}>
                            <Form.Item
                                className="m-right-small"
                                noStyle
                                name={[fieldToRender.name, 'date']}
                                rules={[
                                    FORM_VALIDATORS.validDate,
                                    ...mapRules(fieldToRender.rules),
                                ]}
                            >
                                <Input
                                    placeholder="Date (jj/mm/aaa)"
                                    value={fieldToRender.value ? fieldToRender.value.date : null}
                                    onChange={fieldToRender.onDateChange}
                                    style={fieldToRender.dateStyle}
                                    readOnly={fieldToRender.readOnly}
                                    disabled={fieldToRender.frozen}
                                />
                            </Form.Item>
                            <Form.Item
                                className="m-left-small"
                                noStyle
                                name={[fieldToRender.name, 'time']}
                                rules={mapRules(fieldToRender.rules)}
                            >
                                <TimePicker
                                    placeholder="Heure (HH:MM)"
                                    style={fieldToRender.timeStyle}
                                    disabled={fieldToRender.frozen || fieldToRender.readOnly}
                                    format={format}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                );
            case 'timepicker':
                return (
                    <Form.Item
                        style={{ ...fieldToRender.style }}
                        className="m-left-small"
                        noStyle
                        name={fieldToRender.name}
                        rules={mapRules(fieldToRender.rules)}
                    >
                        <TimePicker
                            placeholder={fieldToRender.placeholder}
                            style={fieldToRender.timeStyle}
                            disabled={fieldToRender.frozen || fieldToRender.readOnly}
                            format={format}
                        />
                    </Form.Item>
                );
            default:
                return <div />;
        }
    };

    return (
        <div className="flex" style={{ alignItems: 'baseline' }}>
            <Descriptions>
                <Descriptions.Item span={withActions ? 3 : 1}>
                    {prepareFieldLabel(field.strongLabel, field.label)}
                </Descriptions.Item>
                <Descriptions.Item>{renderField(field)}</Descriptions.Item>
                {withActions && (
                    <Descriptions.Item>
                        <FieldActions
                            field={field}
                            call={call}
                            onUpdate={onUpdate}
                            fieldFamily={fieldFamily}
                        />
                    </Descriptions.Item>
                )}
                {showExtraFields
                    ? (
                        <>
                            <Descriptions.Item span={3}>
                                <div />
                            </Descriptions.Item>
                            <Descriptions.Item span={3}>
                                <Form.Item>
                                    <div className="flex-column" style={{ marginLeft: '15px' }}>
                                        {field.extraFields.fields.map((f, i) => (
                                            <div>
                                                <Descriptions.Item span={3}>
                                                    {prepareFieldLabel(f.strongLabel, f.label)}
                                                </Descriptions.Item>
                                                <div className="flex">
                                                    <Descriptions.Item className="m-right-medium">
                                                        {renderField(f)}
                                                    </Descriptions.Item>
                                                    {withActions && (
                                                        <Descriptions.Item>
                                                            <FieldActions
                                                                field={f}
                                                                call={call}
                                                                onUpdate={onUpdate}
                                                                fieldFamily={fieldFamily}
                                                                extraFieldIndex={i}
                                                            />
                                                        </Descriptions.Item>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Descriptions.Item>
                        </>
                    )
                    : null}
            </Descriptions>
        </div>
    );
};

DynamicField.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string,
        rules: PropTypes.array,
        strongLabel: PropTypes.bool,
        label: PropTypes.string,
        type: PropTypes.oneOf(['radio', 'input', 'inputNumber']),
        radioStyle: PropTypes.object,
        inputStyle: PropTypes.object,
        inputNumberStyle: PropTypes.object,
        style: PropTypes.object,
        value: PropTypes.any,
        placeholder: PropTypes.string,
        radioFields: PropTypes.array,
        minNumber: PropTypes.number,
        maxNumber: PropTypes.number,
        readOnly: PropTypes.bool,
        frozen: PropTypes.bool,
        onDateChange: PropTypes.func,
        dateStyle: PropTypes.object,
        onTimeChange: PropTypes.func,
        timeStyle: PropTypes.object,
        checkboxFields: PropTypes.array,
        extraFields: PropTypes.shape({
            fields: PropTypes.object,
            displayValue: PropTypes.any,
        }),
    }).isRequired,
    withActions: PropTypes.bool,
    call: PropTypes.object,
    onUpdate: PropTypes.func,
    fieldFamily: PropTypes.string,
};

DynamicField.defaultProps = {
    withActions: false,
    call: null,
    onUpdate: () => {},
    fieldFamily: null,
};

export default DynamicField;
