/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
    Spin,
    Button,
    Table,
} from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from 'antd/lib/typography/Title';
import { getAllQueriesAsync, setQueryList, selectQueryAsync } from '../../../redux/query/actions';
import {
    formatDateTimeFromIso,
} from '../../../helpers/functions';

const QueryList = ({
    user,
    contentIsLoading,
    queryList,
    getAllQueriesAsync,
    setQueryList,
    selectQueryAsync,
}) => {
    useEffect(() => {
        if (user) {
            const query = user.type === 1 ? '?status=pending&isCallCenterAgent=true' : '?status=pending';
            getAllQueriesAsync(query);
        }
        return () => {
            setQueryList([]);
        };
    }, [user]);

    const columns = [
        {
            title: <b>Nature</b>,
            key: 'queryName',
            render: (_, record) => <p>{record.field.label}</p>,
        },
        {
            title: <b>Patient</b>,
            key: 'name',
            render: (_, record) => (
                <p>
                    {`${
                        record.patient.firstName ? record.patient.firstName.value : ''
                    } ${record.patient.lastName ? record.patient.lastName.value : ''}`}

                </p>
            ),
        },
        {
            title: <b>Date de l&apos;appel </b>,
            key: 'call',
            render: (_, record) => (
                <p>
                    {`${
                        formatDateTimeFromIso(record.call.createdAt) ? formatDateTimeFromIso(record.call.createdAt) : 'null'}`}
                </p>
            ),
        },
        {
            title: <b> Date d&apos;envoi</b>,
            key: 'date',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            render: (_, record) => (
                <div className="flex">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            selectQueryAsync(record._id);
                        }}
                        className="m-right-medium"
                        icon={<MessageOutlined />}
                    >
                        Répondre
                    </Button>
                </div>
            ),
        },
    ];

    if (user && user.type !== 1) {
        columns.splice(-1, 1);
    }

    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                <Title level={5}>
                    Queries en attente
                </Title>
                <Table
                    className="m-top-medium"
                    columns={columns}
                    dataSource={queryList}
                    locale={{ emptyText: 'Aucune Query' }}
                />
            </div>
        </Spin>
    );
};

QueryList.propTypes = {
    queryList: PropTypes.array.isRequired,
    getAllQueriesAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    setQueryList: PropTypes.array.isRequired,
    user: PropTypes.object,
    selectQueryAsync: PropTypes.func.isRequired,
};

QueryList.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    queryList: state.queries.queryList,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllQueriesAsync,
        selectQueryAsync,
        setQueryList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(QueryList);
