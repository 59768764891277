/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
    Table, Button, Typography, Spin,
} from 'antd';
import { EyeOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatDateTimeFromIso } from '../../helpers/functions';
import {
    getPendingPatientsAsync,
    selectPatient,
    setPatientsList,
    getAllPatientsAsync,
} from '../../redux/patients/actions';
import ConsentModal from './consentModal';
import ConsultPatientModal from './consultModal';
import AddPatient from './add';

const { Text, Title } = Typography;

const PatientsList = ({
    patientsList,
    getPendingPatientsAsync,
    contentIsLoading,
    setPatientsList,
    getAllPatientsAsync,
    user,
}) => {
    const [selectedPatient, selectPatient] = useState();
    const [openConsentModal, setOpenConsentModal] = useState(false);
    const [openConsultModal, setOpenConsultModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        if (user) {
            if (user.type === 1) getAllPatientsAsync();
            if (user.type === 2) getPendingPatientsAsync();
        }
        return () => {
            setPatientsList([]);
        };
    }, [user]);

    const columns = [
        {
            title: <b>Identifiant</b>,
            key: 'name',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {record.customId ? record.customId : ''}
                </p>
            ),
        },
        {
            title: <b>Nom et Prénom </b>,
            key: 'name',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {`${record.lastName.value} ${record.firstName.value}`}
                </p>
            ),
        },
        {
            title: <b>Carte</b>,
            key: 'card',
            render: (_, record) => (
                <Text
                    className={record.cardColor}
                    style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}
                >
                    {record.cardColor ? record.cardColor : record.cardColor}
                </Text>
            ),
        },
        {
            title: <b>Consentement</b>,
            key: 'card',
            render: (_, record) => (
                <Text style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {record.initialConsent.value}
                </Text>
            ),
        },
        {
            title: <b> Date de création </b>,
            key: 'date',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {formatDateTimeFromIso(record.createdAt)}
                </p>
            ),
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            align: 'center',
            render: (_, record) => {
                const isDisabled = record.countReachability >= 7;

                return (
                    <div className="flex">
                        <Button
                            disabled={isDisabled}
                            type="primary"
                            ghost
                            size="middle"
                            className="m-left-medium"
                            onClick={() => {
                                selectPatient(record);
                                setOpenConsultModal(true);
                            }}
                            icon={<EyeOutlined />}
                        >
                            Consulter
                        </Button>
                        {user && user.type === 2 && (
                            <Button
                                disabled={isDisabled}
                                type="primary"
                                ghost
                                className="m-left-medium"
                                onClick={() => {
                                    selectPatient(record);
                                    setOpenConsentModal(true);
                                }}
                                icon={<SettingOutlined />}
                            >
                                Consentement
                            </Button>
                        )}

                    </div>
                );
            },
        },
    ];

    const handleCloseModal = () => {
        selectPatient(null);
        setOpenConsentModal(false);
        setOpenConsultModal(false);
    };

    if (showAdd) {
        return (<AddPatient handleGoBack={() => setShowAdd(false)} />);
    }

    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                {user && user.type === 1 ? (
                    <>
                        <Title style={{ marginBottom: -50 }} level={5}>
                            Liste des patients
                        </Title>
                        <Button
                            onClick={() => setShowAdd(true)}
                            className="m-top-medium m-bottom-large"
                            style={{ float: 'right', marginTop: '-2px' }}
                            type="primary"
                            size="middle"
                            icon={<PlusOutlined />}
                        >
                            Ajouter un patient
                        </Button>
                    </>
                ) : (
                    <Title style={{ marginBottom: 30 }} level={5}>
                        Confirmation des consentements
                    </Title>

                )}
                <Table
                    className="m-top-medium"
                    columns={columns}
                    dataSource={patientsList}
                    locale={{ emptyText: 'Aucun patient' }}
                />
                <ConsentModal
                    open={openConsentModal}
                    selectedPatient={selectedPatient}
                    handleClose={handleCloseModal}
                />
                <ConsultPatientModal
                    selectedPatient={selectedPatient}
                    open={openConsultModal}
                    handleClose={handleCloseModal}
                />
            </div>
        </Spin>
    );
};

PatientsList.propTypes = {
    patientsList: PropTypes.array.isRequired,
    getPendingPatientsAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    getAllPatientsAsync: PropTypes.func.isRequired,
    user: PropTypes.object,
};

PatientsList.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    patientsList: state.patients.patientsList,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getPendingPatientsAsync,
        selectPatient,
        setPatientsList,
        getAllPatientsAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(PatientsList);
