/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Timeline,
    Spin,
    Result,
    Button,
    Menu,
} from 'antd';
import { bindActionCreators } from 'redux';
import HeadBreadCrumb from '../../../components/HeadBreadCrumb';
import {
    formatDateTimeFromIso,
    getFullNameDynamicF,
} from '../../../helpers/functions';
import { updateCallAsync } from '../../../redux/call/actions';
import DynamicForm from './dynamicForm';


const ConsultCalls = ({
    callsList,
    contentIsLoading,
    handleGoBack,
    selectedPatient,
    events,
}) => (
    <Spin spinning={contentIsLoading}>
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Historique des appels"
                secondSectionName={
                    selectedPatient ? getFullNameDynamicF(selectedPatient) : 'Patient'
                }
                handleGoBack={handleGoBack}
            />
            <div className="flex-column section-content consultations-hstory">
                {callsList.length ? (
                    <Timeline>
                        {callsList.map(call => (
                            <Timeline.Item
                                key={call._id}
                                color={call.isReachable === true ? 'blue' : 'red'}
                                className="timeline-item-consultations"
                            >
                                <DynamicForm call={call} />
                            </Timeline.Item>
                        ))}
                    </Timeline>
                ) : (
                    <Result
                        status="404"
                        title="Aucun appel pour ce patient"
                        extra={(
                            <Button onClick={() => handleGoBack()} type="primary">
                                Retour
                            </Button>
                        )}
                    />
                )}

                {events.length ? (
                    <Timeline>
                        {events.map(event => (
                            <Timeline.Item
                                key={event._id}
                                color="yellow"
                                className="timeline-item-consultations"
                            >
                                <Menu mode="inline" className="call-history-menu">
                                    <Menu.SubMenu title={`Evénement indésirable le ${formatDateTimeFromIso(event.createdAt)}`} />
                                </Menu>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                ) : null

                }

            </div>
        </div>
    </Spin>
);

ConsultCalls.propTypes = {
    callsList: PropTypes.array.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    handleGoBack: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    events: PropTypes.array.isRequired,
};

const stateToProps = state => ({
    callsList: state.calls.callsList,
    contentIsLoading: state.loading.contentIsLoading,
    events: state.adverse.events,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateCallAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ConsultCalls);
