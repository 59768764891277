import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import moment from 'moment';


const MyPicker = (props) => {
    const onBlur = (elem) => {
        const value = moment(elem.target.value, props.format);
        if (value && value.isValid() && props.onChange) {
            props.onChange(value, elem.target.value);
        }
    };
    return <TimePicker {...props} onBlur={onBlur} />;
};

MyPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    format: PropTypes.string.isRequired,
};


export default MyPicker;
