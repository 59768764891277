/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import * as QueryAPI from '../../../api/query';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const ADD_QUERY = 'ADD_QUERY';
export const SET_QUERY_LIST = 'SET_QUERY_LIST';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const SELECT_QUERY = 'SELECT_QUERY';
export const REMOVE_QUERY = 'REMOVE_QUERY';

export const removeQuery = value => ({
    type: REMOVE_QUERY,
    payload: value,
});

export const setQueryList = value => ({
    type: SET_QUERY_LIST,
    payload: value,
});

export const addQuery = value => ({
    type: ADD_QUERY,
    payload: value,
});

export const selectQuery = value => ({
    type: SELECT_QUERY,
    payload: value,
});

export const updateQuery = value => ({
    type: UPDATE_QUERY,
    payload: value,
});

export const getAllQueriesAsync = query => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await QueryAPI.getAll(query);
        dispatch(setQueryList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const createQueryAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await QueryAPI.create(body);
        dispatch(addQuery(res.data));
        message.success('Query ajoutée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const selectQueryAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await QueryAPI.getById(id);
        dispatch(selectQuery(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const updateQueryAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await QueryAPI.update(id, body);
        dispatch(updateQuery(res.data));
        message.success('Modification effectuée avec succès');
        if (body.status) {
            dispatch(removeQuery(res.data._id));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const pushQueryMessageAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await QueryAPI.pushMessage(id, body);
        dispatch(updateQuery(res.data));
        dispatch(selectQuery(res.data));
        message.success('Message ajouté avec succès');
        if (body.status) {
            dispatch(removeQuery(res.data._id));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
