/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    ADD_QUERY, SET_QUERY_LIST, SELECT_QUERY, UPDATE_QUERY, REMOVE_QUERY,
} from '../actions';

const initialState = {
    queryList: [],
    selectedQuery: null,
};

const queries = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUERY_LIST:
            return { ...state, queryList: action.payload };
        case ADD_QUERY:
            return {
                ...state,
                queryList: [action.payload, ...state.queryList],
            };
        case SELECT_QUERY:
            return { ...state, selectedQuery: action.payload };

        case REMOVE_QUERY:
            return {
                ...state,
                queryList: state.queryList.filter(
                    element => element._id !== action.payload,
                ),
            };

        case UPDATE_QUERY:
            // eslint-disable-next-line no-case-declarations
            const queryList = [];
            state.queryList.map((query) => {
                if (query._id === action.payload._id) {
                    queryList.push(action.payload);
                } else queryList.push(query);
            });
            return { ...state, queryList };
        default:
            return state;
    }
};

export default queries;
