import React from 'react';
import {
    Card, Form, Input, Checkbox, Button, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    LoginOutlined, UserOutlined, LockOutlined,
} from '@ant-design/icons';
import { loginAsync } from '../../redux/auth/actions';
// import PropTypes from 'prop-types';


import FORM_VALIDATORS from '../../helpers/formRules';

const Login = ({ loginAsync }) => (
    <Card
        className="login-card"
        title={(
            <div className="flex-column">
                <div className="p-medium flex">
                    <img src="/assets/logo-eshmoun.png" alt="katomi-logo" className="m-auto" style={{ width: 200 }} />

                </div>
                <Divider />
                <div className="flex">
                    <LoginOutlined className="m-right-small" />
                    Connexion

                </div>
            </div>
        )}
    >
        <Form
            name="login-form"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={values => loginAsync(values)}
        >
            <Form.Item
                name="email"
                rules={[FORM_VALIDATORS.required, FORM_VALIDATORS.email]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item name="password" rules={[FORM_VALIDATORS.required]}>
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Se souvenir de moi</Checkbox>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Button
                    block
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: 5 }}
                >
                    Se connecter
                </Button>
            </Form.Item>

        </Form>
    </Card>
);

Login.propTypes = {
    loginAsync: PropTypes.func.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        loginAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(Login);
