export default {
    'auth/user-not-found': {
        fr: "Aucun compte n'est associé à cette adresse e-mail.",
        en: 'No account associated with this e-mail address.',
    },
    'auth/invalid-email': {
        fr: "L'adresse email saisie est invalide.",
        en: 'Invalide e-mail address.',
    },
    'auth/user-disabled': {
        fr: 'Le compte associé à cette adresse e-mail a été désactivé.',
        en: 'Your account is disabled.',
    },

    'auth/wrong-password': {
        fr: 'Mot de passe incorrect.',
        en: 'Incorrect password.',
    },
    'auth/user-token-expired': {
        fr: 'Votre session a expirée. Veuillez vous reconnecter.',
        en: 'Session expired. reconnect please.',
    },
    'auth/invalid-user-token': {
        fr: 'Votre session a expirée. Veuillez vous reconnecter.',
        en: 'Session expired. reconnect please.',
    },

    'auth/network-request-failed': {

        fr: 'Impossible de communiquer avec le serveur. Veuillez vérifier votre connexion à internet.',
        en: 'Network error. please verify your internet connexion.',
    },
    'auth/email-already-in-use': {
        fr: 'Vous avez déja un compte associé a cet adresse e-mail.',
        en: 'you have already an account with thi e-mail address.',
    },
    'auth/too-many-requests': {
        fr: 'Vous avez dépassé le nombre de requêtes autorisés, veuillez essayer de nouveau dans une minute.',
        en: 'You have exceeded the request allowed number, please retry in a minute.',
    },

    notifEmailVerificationSuccessMessage: {
        fr: 'E-mail envoyé',
        en: 'E-mail sent',
    },
    notifEmailVerificationSuccessDescription: {
        fr: "Un e-mail de confirmation vous a été envoyé, veuillez le consulter afin d'activer votre compte.",
        en: 'A confirmation email has been successfuly sent, please check it in order to activate your account.',
    },
    notifPasswordResetSuccessDescription: {
        fr: 'un e-mail de récupération de votre mot de passe vous à été envoyé avec succès.',
        en: 'A password recovery e-mail has been successfuly sent to your e-mail address.',
    },
    errorNotificationMessage: {
        fr: 'Erreur',
        en: 'Error',
    },
    unknownErrorDescription: {
        fr: 'Une erreur inconnue est survenue.',
        en: 'Unknown error occured.',
    },
    updateEmailSuccess: {
        fr: 'Adresse e-mail modifiée.',
        en: 'E-mail address updated successfully.',
    },
    sucessDisconnect: {
        fr: 'Vous êtes à présent déconnecté.',
        en: 'Disconnected successfully.',
    },
    emailStillUnconfirmed: {
        fr: "Votre adresse e-mail n'est pas encore validée.",
        en: 'You need to follow the e-mail instructions in order to activate your account.',
    },
};
