
import { message } from 'antd';
import * as CallsAPI from '../../../api/call';
import { sessionExpired } from '../../auth/actions';
import { updatePatient, updatePatientAsync } from '../../patients/actions';
import { setContentIsLoading } from '../../loading/actions';


export const SET_CALLS_LIST = 'SET_CALLS_LIST';
export const UPDATE_CALL = 'UPDATE_CALL';
export const SELECT_AUDIT = 'SELECT_AUDIT';


export const setCallsList = value => ({
    type: SET_CALLS_LIST,
    payload: value,
});


export const updateCall = value => ({
    type: UPDATE_CALL,
    payload: value,
});


export const selectAudit = value => ({
    type: SELECT_AUDIT,
    payload: value,
});


export const getAllCallsAsync = query => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallsAPI.getAll(query);
        dispatch(setCallsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createCallAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallsAPI.create(body);
        message.success('Appel ajouté avec succès');
        dispatch(updatePatient(res.data.patient));
        if (body.isReachable || body.isIncoming) {
            dispatch(updatePatientAsync(body.patient, { countReachability: 0 }));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateCallAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallsAPI.update(id, body);
        message.success('Modification effectuée avec succès.');
        dispatch(updateCall(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectAuditAsync = (id, query) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallsAPI.getAuditTrial(id, query);
        dispatch(selectAudit(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
