/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    SET_PATIENTS_LIST,
    SELECT_PATIENT,
    ADD_PATIENT,
    UPDATE_PATIENT,
    REMOVE_PATIENT,

} from '../actions';

const initialState = {
    patientsList: [],
    selectedPatient: null,
    selectedAudit: null,
};

const patients = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENTS_LIST:
            return { ...state, patientsList: action.payload };
        case ADD_PATIENT:
            return {
                ...state,
                patientsList: [action.payload, ...state.patientsList],
            };
        case SELECT_PATIENT:
            return { ...state, selectedPatient: action.payload };
        case REMOVE_PATIENT:
            return {
                ...state,
                patientsList: state.patientsList.filter(
                    element => element._id !== action.payload,
                ),
            };

        case UPDATE_PATIENT:
            // eslint-disable-next-line no-case-declarations
            const patientsList = [];
            state.patientsList.map((patient) => {
                if (patient._id === action.payload._id) {
                    patientsList.push(action.payload);
                } else patientsList.push(patient);
            });
            return { ...state, patientsList };

        default:
            return state;
    }
};

export default patients;
