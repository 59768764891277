import { notification } from 'antd';
import { db } from '../firebase';

// eslint-disable-next-line consistent-return
const notificationsListner = async (uid) => {
    try {
        const docRef = db.collection('notifications').doc(uid);
        const unsubscriber = await docRef.onSnapshot((doc) => {
            // eslint-disable-next-line max-len
            // TODO check the current route and refresh the data. (add cb to be executed in params for instance)

            if (doc.data() && doc.data().notifications) {
                // eslint-disable-next-line array-callback-return
                doc.data().notifications.forEach((ev) => {
                    notification.info({
                        message: 'Nouvelle notification',
                        description: ev.content,
                        duration: 7000,
                    });
                });
                docRef.set({ notifications: [] });
            }
        });
        return unsubscriber;
    } catch (err) {
        // null is not a pointer of undefined
    }
};

export default notificationsListner;
