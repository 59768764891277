/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal, Tree, Timeline,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAudit } from '../../redux/call/actions';
import { formatDateTimeFromIso } from '../../helpers/functions';

const prepareTree = (c, i) => {
    const res = [];
    res.push({
        title: (
            <>
                <b>
                    {'Date : '}
                </b>
                {formatDateTimeFromIso(c.createdAt)}
            </>
        ),
        key: `${i}-${c.date}`,
        children: [
            {
                title: (
                    <>
                        <b>
                            {i === 0 ? 'Valeur initiale : ' : 'Nouvelle valeur : '}
                        </b>
                        {c.value}
                    </>
                ),
                key: `${i}-${c.value}`,
            },
        ],
    });
    return res;
};

const AuditTrial = ({ selectedAudit, selectAudit }) => (
    <Modal width={820} visible={selectedAudit} title="Historique des modifications" footer={null} onCancel={() => selectAudit(null)}>
        {selectedAudit && selectedAudit.map((audit, i) => (
            <Timeline.Item key={audit._id}>
                <Tree treeData={prepareTree(audit, i)} />
            </Timeline.Item>
        ))}
    </Modal>
);


AuditTrial.propTypes = {
    selectedAudit: PropTypes.array,
    selectAudit: PropTypes.func.isRequired,
};

AuditTrial.defaultProps = {
    selectedAudit: null,
};

const stateToProps = state => ({
    selectedAudit: state.calls.selectedAudit,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectAudit,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AuditTrial);
