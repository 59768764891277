import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography, Card, Form, Radio,
} from 'antd';

const Rouge = require('./rouge.jpg');
const Orange = require('./orange.jpg');
const Bleu = require('./bleu.jpg');

const { Title } = Typography;

const CardPicker = ({ showOrange, onSelect }) => (
    <div>
        <Title level={4} className="m-bottom-large center-text">
            Choisissez la carte du patient
        </Title>
        <div
            className="flex p-top-meidum"
            style={{ justifyContent: 'space-around' }}
        >
            <Form.Item noStyle name="cardColor">
                <Radio.Group
                    className="flex"
                    style={{ flex: 1, justifyContent: 'space-around' }}
                >
                    <Card
                        className="card-item"
                        style={{ width: '25%', height: '100%' }}
                        onClick={() => onSelect('Violette')}
                    >
                        <img
                            src={Rouge}
                            alt="rouge"
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <Radio value="Violette">Violette</Radio>
                    </Card>
                    {showOrange ? (
                        <Card
                            className="card-item"
                            style={{ width: '25%', height: '100%' }}
                            onClick={() => onSelect('Orange')}
                        >
                            <img
                                src={Orange}
                                alt="orange"
                                style={{ width: '100%', height: 'auto' }}
                            />
                            <Radio value="Orange">Orange</Radio>
                        </Card>
                    ) : null}
                    <Card
                        className="card-item"
                        style={{ width: '25%', height: '100%' }}
                        onClick={() => onSelect('Bleue')}
                    >
                        <img
                            src={Bleu}
                            alt="bleu"
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <Radio value="Bleue">Bleue</Radio>
                    </Card>
                </Radio.Group>
            </Form.Item>
        </div>
    </div>
);

CardPicker.propTypes = {
    showOrange: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
};

CardPicker.defaultProps = {
    showOrange: true,
};

export default CardPicker;
