import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox, Input, InputNumber, Radio,
} from 'antd';

import './styles.css';

const { TextArea } = Input;

// TODO add form validation
const NestedCheckboxInputs = ({
    label,
    values,
    onCheckStateChange,
    checkList,
    onCheckListStateChange,
    onTextFieldChange,
    onNumericFieldChange,
    ...props
}) => (
    <div className="nested-checkbox-inputs-container">
        <div className="nested-checkbox-inputs-row">
            <Checkbox onChange={onCheckStateChange} value={values.value} {...props}>
                {label}
            </Checkbox>
        </div>
        {values.value ? (
            <>
                <div className="nested-checkbox-inputs-row">
                    {checkList.map(checkItem => (
                        <Checkbox
                            onChange={onCheckListStateChange(checkItem)}
                            value={
                                values.subSymptoms
                                && values.subSymptoms.indexOf(checkItem) !== -1
                            }
                        >
                            {checkItem}
                        </Checkbox>
                    ))}
                </div>
                <div className="nested-checkbox-inputs-row">
                    <p style={{ marginRight: '1rem' }}>
                        <span style={{ color: 'red' }}>* </span>
                        Date de début :
                    </p>
                    <Input
                        onChange={onTextFieldChange('startDate')}
                        value={values.startDate}
                        placeholder="eg: 01/01/2000"
                    />
                </div>
                <div className="nested-checkbox-inputs-row">
                    <p style={{ marginRight: '1rem' }}>
                        <span style={{ color: 'red' }}>* </span>
                        Description :
                    </p>
                    <TextArea
                        rows={2}
                        onChange={onTextFieldChange('description')}
                        value={values.description}
                        placeholder="mots du patient"
                    />
                </div>
                <div className="nested-checkbox-inputs-row">
                    <p style={{ marginRight: '1rem' }}>
                        <span style={{ color: 'red' }}>* </span>
                        Intensité :
                    </p>
                    <InputNumber
                        min={1}
                        max={10}
                        onChange={onNumericFieldChange('intensity')}
                        value={values.intensity}
                        placeholder="1 à 10"
                    />
                </div>
                <div className="nested-checkbox-inputs-row">
                    <p style={{ marginRight: '1rem' }}>
                        <span style={{ color: 'red' }}>* </span>
                        A informé son médecin traitant ?
                    </p>
                    <Radio.Group
                        onChange={onTextFieldChange('hasInformedDoctor')}
                        value={values.hasInformedDoctor}
                    >
                        <Radio value>Oui</Radio>
                        <Radio value={false}>Non</Radio>
                    </Radio.Group>
                </div>
            </>
        ) : null}
    </div>
);

NestedCheckboxInputs.defaultProps = {
    label: '',
    checkList: [],
};

NestedCheckboxInputs.propTypes = {
    label: PropTypes.string,
    values: PropTypes.shape({
        value: PropTypes.bool.isRequired,
        subSymptoms: PropTypes.array,
        startDate: PropTypes.string,
        description: PropTypes.string,
        intensity: PropTypes.number,
        hasInformedDoctor: PropTypes.bool,
    }).isRequired,
    onCheckStateChange: PropTypes.func.isRequired,
    checkList: PropTypes.array,
    onCheckListStateChange: PropTypes.func.isRequired,
    onTextFieldChange: PropTypes.func.isRequired,
    onNumericFieldChange: PropTypes.func.isRequired,
};

export default NestedCheckboxInputs;
