/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Card,
    Steps,
    Form,
    Spin,
    Button,
    Table,
    Typography,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Search from 'antd/lib/input/Search';
import {
    getAllOnboardedPatientsAsync,
    setPatientsList,
} from '../../redux/patients/actions';
import PatientsList from './patientsList';
// import FORM_VALIDATORS from '../../helpers/formRules';
import './styles.css';
import {
    createAdverseAsync,
    getAllEventsAsync,
    selectEventAsync,
    setAdverseList,
} from '../../redux/adverse/actions';
import {
    formatDateTimeFromIso,
    getFullNameDynamicF,
} from '../../helpers/functions';
import UpdateEvent from './updateEvent';
import NestedCheckboxInputs from '../../components/NestedCheckboxInputs';
import symptomsInfo from './symptomsInfo.json';


const { Step } = Steps;
const { Text } = Typography;

const CalLCenterAddForm = ({ form, onFinish }) => {
    const [symptoms, setSymptoms] = useState({
        anemia: { value: false },
        hematomas: { value: false },
        dizziness: { value: false },
        headache: { value: false },
        urinaryTractInfections: { value: false },
        insomnia: { value: false },
        inactivity: { value: false },
        caugh: { value: false },
        pruritus: { value: false },
        diffuseBonePain: { value: false },
        abdominalDiscomfort: { value: false },
        earlySatiety: { value: false },
        weightLoss: { value: false },
        nightSweat: { value: false },
        other: { value: false },
    });

    const handleSymptomCheck = fieldName => (event) => {
        setSymptoms({
            ...symptoms,
            [fieldName]: { ...symptoms[fieldName], value: event.target.checked },
        });
    };

    const handleToggleSubSymptom = fieldName => subSymptom => (event) => {
        const currentSubSymptoms = symptoms[fieldName].subSymptoms;

        if (!currentSubSymptoms) {
            return setSymptoms({
                ...symptoms,
                [fieldName]: { ...symptoms[fieldName], subSymptoms: [subSymptom] },
            });
        }

        const updatedSubSymptoms = [
            ...new Set(
                event.target.checked
                    ? [...currentSubSymptoms, subSymptom]
                    : currentSubSymptoms.filter(item => item !== subSymptom),
            ),
        ];

        return setSymptoms({
            ...symptoms,
            [fieldName]: { ...symptoms[fieldName], subSymptoms: updatedSubSymptoms },
        });
    };

    const handleSymptomTextFieldsChange = fieldName => textField => (event) => {
        setSymptoms({
            ...symptoms,
            [fieldName]: { ...symptoms[fieldName], [textField]: event.target.value },
        });
    };

    const handleSymptomNumericFieldsChange = fieldName => numericField => (value) => {
        setSymptoms({
            ...symptoms,
            [fieldName]: { ...symptoms[fieldName], [numericField]: value },
        });
    };

    // NOTE should not be needed after refactoring symptoms component to use antd's forms
    const onFinishWithSymptoms = (formValues) => {
        const checkedSymptoms = {};
        Object.entries(symptoms).forEach(([symptom, symptomData]) => {
            const { value, ...symptomValues } = symptomData;
            if (value) checkedSymptoms[symptom] = symptomValues;
        });

        return onFinish({ ...formValues, symptoms: checkedSymptoms });
    };

    return (
        <Form form={form} onFinish={onFinishWithSymptoms}>

            <h3>Symptômes :</h3>
            <Form.Item>
                {/* TODO refactor to enable usage of antd's forms */}
                {Object.keys(symptoms).map(symptom => (
                    <div className="symptoms-list-item">
                        {/* TODO refactor component for single change handler */}
                        <NestedCheckboxInputs
                            label={symptomsInfo[symptom].label}
                            values={symptoms[symptom]}
                            onCheckStateChange={handleSymptomCheck(symptom)}
                            checkList={symptomsInfo[symptom].subSymptoms}
                            onCheckListStateChange={handleToggleSubSymptom(symptom)}
                            onTextFieldChange={handleSymptomTextFieldsChange(symptom)}
                            onNumericFieldChange={handleSymptomNumericFieldsChange(symptom)}
                        />
                    </div>
                ))}
            </Form.Item>

            <Form.Item>
                <Button type="primary" className="center-button" htmlType="submit">
                    Envoyer
                </Button>
            </Form.Item>
        </Form>
    );
};

CalLCenterAddForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
};


const filter = (query, data) => {
    const newQuery = query
        .replace(/[^a-z0-9]/gi, '')
        .split('')
        .join('.*');
    return data.filter((item) => {
        const regex = new RegExp(newQuery, 'i');
        return (
            regex.test(item.patient.firstName.value) || regex.test(item.patient.lastName.value)
        );
    });
};

const AdverseEvent = ({
    user,
    contentIsLoading,
    getAllOnboardedPatientsAsync,
    patientsList,
    createAdverseAsync,
    eventsList,
    getAllEventsAsync,
    selectEventAsync,
    selectedEvent,
}) => {
    const [current, setCurrent] = useState(0);
    const [patient, selectPatient] = useState();
    const [currentItems, setCurrentItems] = useState(eventsList);
    const [form] = Form.useForm();

    useEffect(() => {
        if (user) {
            if (user.type === 1) {
                getAllOnboardedPatientsAsync('');
            } else {
                getAllEventsAsync();
            }
        }
    }, [user]);

    useEffect(() => {
        setCurrentItems(eventsList);
    }, [eventsList]);


    const onFinish = (values) => {
        createAdverseAsync({
            ...values,
            patient: patient._id,
            investigatorInfo: `${patient.doctor.firstName.value
            } ${
                patient.doctor.lastName.value}`,
            investigatorCenter: patient.doctor.address.value,
        });
        form.resetFields();
        selectPatient(null);
        setCurrent(0);
    };

    const handleSelect = (p) => {
        selectPatient(p);
        setCurrent(1);
    };

    const showStep = () => {
        if (current === 0) {
            return <PatientsList data={patientsList} onSelect={handleSelect} />;
        }
        return <CalLCenterAddForm form={form} onFinish={onFinish} />;
    };

    const handleSearch = (searchValue) => {
        const filtredData = filter(searchValue, eventsList);
        setCurrentItems(filtredData);
    };

    const columns = [
        {
            title: <b>Nom et Prénom</b>,
            key: 'name',
            render: (_, record) => <p>{`${getFullNameDynamicF(record.patient)}`}</p>,
        },
        {
            title: <b>Carte</b>,
            key: 'card',
            render: (_, record) => (
                <Text className={record.patient.cardColor}>
                    {record.patient.cardColor}
                </Text>
            ),
        },
        {
            title: <b> Date de déclaration</b>,
            key: 'date',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            render: (_, record) => (
                <div className="flex">
                    <Button
                        type="primary"
                        ghost
                        className="m-left-medium"
                        onClick={() => {
                            selectEventAsync(record._id);
                        }}
                        icon={<SettingOutlined />}
                    >
                        Mettre a jour
                    </Button>
                </div>
            ),
        },
    ];

    if (selectedEvent) {
        return (
            <div className="section-content">
                <UpdateEvent />
            </div>
        );
    }

    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                {user && user.type === 1 ? (
                    <>
                        <Title level={5} style={{ marginBottom: '20px' }}>Ajout d&apos;un événement indésirable</Title>

                        <Card>
                            <Steps size="small" current={current} onChange={setCurrent}>
                                <Step title="Choisir un patient" />
                                <Step disabled={!patient} title="Description" />
                            </Steps>
                            <div style={{ margin: 'auto', marginTop: 30, padding: 24 }}>
                                {showStep()}
                            </div>
                        </Card>
                    </>
                ) : (
                    <>
                        <Title level={5} style={{ marginBottom: '20px' }}>Liste des événements indésirables</Title>
                        <Search
                            placeholder="Chercher par nom ou prénom"
                            allowClear
                            className="m-bottom-medium"
                            style={{ maxWidth: '50%' }}
                            onChange={e => handleSearch(e.target.value)} />
                        <Table
                            className="m-top-medium"
                            columns={columns}
                            dataSource={currentItems}
                            locale={{ emptyText: 'Aucun événement' }} />
                    </>
                )}
            </div>
        </Spin>
    );
};

AdverseEvent.propTypes = {
    contentIsLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    getAllOnboardedPatientsAsync: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
    eventsList: PropTypes.array.isRequired,
    createAdverseAsync: PropTypes.func.isRequired,
    getAllEventsAsync: PropTypes.func.isRequired,
    selectEventAsync: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
};

AdverseEvent.defaultProps = {
    user: null,
    selectedEvent: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    patientsList: state.patients.patientsList,
    eventsList: state.adverse.events,
    selectedEvent: state.adverse.selectedEvent,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllOnboardedPatientsAsync,
        setPatientsList,
        createAdverseAsync,
        getAllEventsAsync,
        selectEventAsync,
        setAdverseList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AdverseEvent);
