export default {
    intensity: {
        name: 'intensity',
        label: 'Gravité',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Grave', value: 'Grave' },
            { label: 'Non grave', value: 'Non grave' },
        ],
    },
    causality: {
        name: 'causality',
        label: "Lien de causalité avec l'essai",
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Hautement probable', value: 'Hautement probable' },
            { label: 'Probable', value: 'Probable' },
            { label: 'Possible', value: 'Possible' },
            { label: 'Douteux', value: 'Douteux' },
            { label: 'Non évaluable', value: 'Non évaluable' },
            { label: 'Sans relation', value: 'Sans relation' },
        ],
    },
    correctingTreatment: {
        name: 'correctingTreatment',
        label: 'Traitement correcteur',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    exclusion: {
        name: 'exclusion',
        label: "Sortie d'étude",
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    treatmentChange: {
        name: ['treatmentChange', 'value'],
        label: 'Y a-t-il eu changement de traitement ?',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    doctorAlert: {
        name: ['doctorAlert', 'value'],
        label: 'Communication de l’effet indésirable au médecin traitant',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' }, // NOTE Has extra field already added
            { label: 'Non', value: 'Non' },
        ],
    },
    studyNumber: {
        name: 'studyNumber',
        label: 'Etude (n° enregistrement autorisation)',
        rules: ['required'],
        type: 'input',
        strongLabel: true,
    },
    investigatorCenter: {
        name: 'investigatorCenter',
        label: 'Adresse du cabinet/hopital du médecin',
        rules: ['required'],
        type: 'input',
        strongLabel: true,
    },
    investigatorInfo: {
        name: 'investigatorInfo',
        label: 'Nom du médecin',
        rules: ['required'],
        type: 'input',
        strongLabel: true,
    },
    declaration: {
        name: ['declaration', 'value'],
        label: 'Déclaration',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Initiale', value: 'Initiale' },
            { label: 'Suivie', value: 'Suivie' }, // NOTE Has extra field already added
        ],
    },
    issue: {
        name: ['issue', 'value'],
        label: 'Issue',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Décès inattendu', value: 'Décès inattendu' }, // NOTE Has extra field already added
            { label: 'Mise en jeu du pronostic vital', value: 'Mise en jeu du pronostic vital' },
            { label: "Nécessite ou prolonge l'hospitalisation", value: "Nécessite ou prolonge l'hospitalisation" }, // NOTE Has 2 extra field already added
            { label: 'En cours', value: 'En cours' },
            { label: 'Incapacité ou invalidité', value: 'Incapacité ou invalidité' },
            { label: 'Autre', value: 'Autre' }, // NOTE Has extra field already added
        ],
    },
    evolution: {
        name: 'evolution',
        label: 'Evolution',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Résolu', value: 'Résolu' },
            { label: 'En résolution', value: 'En résolution' },
            { label: 'Résolu avec séquelle', value: 'Résolu avec séquelle' },
            { label: 'Fatal', value: 'Fatal' },
            { label: 'Non connu', value: 'Non connu' },
        ],
    },
    limitation: {
        name: 'limitation',
        label: 'Limitation',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },

};
