/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Divider, Form, Input, Typography, Card, Button, Table, Radio,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import emptyValues from './emptyValues';
import DynamicField from '../../components/DynamicField';
import FORM_VALIDATORS from '../../helpers/formRules';
import { selectAdverse, updateAdverseAsync } from '../../redux/adverse/actions';
import { getFullNameDynamicF, structureFields } from '../../helpers/functions';
import HeadBreadCrumb from '../../components/HeadBreadCrumb';
import symptomsInfo from './symptomsInfo.json';

const { Title } = Typography;

const UpdateEvent = ({ selectedEvent, updateAdverseAsync, selectAdverse }) => {
    const [values, setValues] = useState();
    const [DoctorAlertExtra, showDoctorAlertExtra] = useState(false);
    const [TratmentChangeExtra, showTreatmentChangeExtra] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedEvent) {
            setValues({ ...emptyValues, ...selectedEvent });
            form.setFieldsValue({
                doctorInfos: selectedEvent.investigatorInfo,
                investigationCenter: selectedEvent.investigatorCenter,
                phoneNumber: selectedEvent.patient.doctor.phoneNumber.value,
            });
            const keys = Object.keys(selectedEvent);
            keys.map((k) => {
                form.setFieldsValue({ [k]: selectedEvent[k].value });
                if (k === 'doctorAlert' && selectedEvent[k].value.value === 'Oui') {
                    showDoctorAlertExtra(true);
                }
                if (k === 'treatmentChange' && selectedEvent[k].value.value === 'Oui') {
                    showTreatmentChangeExtra(true);
                }
            });
        }
    }, [selectedEvent]);

    const handleValueChange = (val) => {
        if (val.doctorAlert && val.doctorAlert.value === 'Oui') {
            showDoctorAlertExtra(true);
        }
        if (val.doctorAlert && val.doctorAlert.value === 'Non') {
            showDoctorAlertExtra(false);
        }
        if (val.treatmentChange && val.treatmentChange.value === 'Oui') {
            showTreatmentChangeExtra(true);
        }
        if (val.treatmentChange && val.treatmentChange.value === 'Non') {
            showTreatmentChangeExtra(false);
        }
    };

    const onFinish = (vals) => {
        const body = {
            ...values,
            ...structureFields(values, vals),
        };
        updateAdverseAsync(selectedEvent._id, body);
    };

    if (!values) return <div />;

    return (
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Evenements Indésirables"
                secondSectionName={getFullNameDynamicF(selectedEvent.patient)}
                handleGoBack={() => selectAdverse(null)}
            />
            <Card>
                <Form>
                    <Title level={5}> Nom et Prénom du patient</Title>
                    <Input
                        value={`${values.patient.lastName.value} ${values.patient.firstName.value}`}
                        readOnly
                    />
                    <Divider />
                    <Title level={5}>Symptômes indiqués par le callcenter</Title>
                    <Table
                        columns={[
                            {
                                title: 'Symptôme',
                                dataIndex: 'symptom',
                                key: 'symptom',
                                render: symptom => <p>{symptomsInfo[symptom].label}</p>,
                            },
                            {
                                title: '',
                                dataIndex: 'subSymptoms',
                                key: 'subSymptoms',
                                responsive: ['lg'],
                                render: arr => <p>{arr ? arr.join(' , ') : ''}</p>,
                            },
                            {
                                title: 'Description',
                                dataIndex: 'description',
                                key: 'description',

                            },
                            {
                                title: 'Date de début',
                                dataIndex: 'startDate',
                                key: 'startDate',
                            },
                            {
                                title: 'Intensité (1-10)',
                                dataIndex: 'intensity',
                                key: 'intensity',
                            },
                            {
                                title: 'A informé médecin traitant',
                                dataIndex: 'hasInformedDoctor',
                                key: 'hasInformedDoctor',
                                render: bool => <p>{bool ? 'Oui' : 'Non'}</p>,
                            },
                        ]}
                        dataSource={
                            values.symptoms
                                ? Object.entries(values.symptoms).map(
                                    ([symptom, symptomData]) => ({ symptom, ...symptomData }),
                                )
                                : []
                        }
                    />

                    {/* <Input.TextArea value={values.description} readOnly /> */}
                    {/* <Title level={5}>Date de début de l&apos;événement</Title>
                    <Input value={values.startDate} readOnly />
                    <Divider />
                    {values.endDate ? (
                        <>
                            <Title level={5}>Date de fin de l&apos;événement</Title>
                            <Input value={values.endDate} readOnly />
                            <Divider />
                        </>
                    ) : null} */}
                    <Form
                        form={form}
                        onValuesChange={handleValueChange}
                        onFinish={onFinish}
                    >
                        <DynamicField field={values.treatmentChange} />
                        {TratmentChangeExtra ? (
                            <div style={{ marginLeft: 34 }} className="flex">
                                <Form.Item
                                    style={{ width: 300 }}
                                    rules={[FORM_VALIDATORS.required]}
                                    className="m-right-medium"
                                    label="Nouveau traitement"
                                    name={['treatmentChange', 'newTreatment']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: 200 }}
                                    rules={[FORM_VALIDATORS.required]}
                                    className="m-right-medium"
                                    label="Nouvelle dose"
                                    name={['treatmentChange', 'newDose']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: 200 }}
                                    rules={[FORM_VALIDATORS.required]}
                                    className="m-right-medium"
                                    label="Arrêt du Jakavi"
                                    name={['treatmentChange', 'jakaviStop']}
                                >
                                    <Radio.Group>
                                        <Radio value="Oui">Oui</Radio>
                                        <Radio value="Non">Non</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        ) : null}
                        <DynamicField field={values.doctorAlert} />
                        {DoctorAlertExtra ? (
                            <div style={{ marginLeft: 34 }} className="flex">
                                <Form.Item
                                    style={{ width: 200 }}
                                    rules={[FORM_VALIDATORS.validDate]}
                                    className="m-right-medium"
                                    label="Date"
                                    name={['doctorAlert', 'date']}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        ) : null}


                        <Form.Item
                            name="doctorInfos"
                            label="Nom et prénom du médecin"
                            className="m-right-medium"
                            style={{ fontWeight: 'bold' }}
                        >
                            <Input style={{ marginLeft: 329, width: 400 }} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="investigationCenter"
                            label="Adresse du médecin"
                            className="m-right-medium"
                            style={{ fontWeight: 'bold' }}
                        >
                            <Input style={{ marginLeft: 374, width: 400 }} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="phoneNumber"
                            label="Numéro de téléphone du médecin"
                            className="m-right-medium"
                            style={{ fontWeight: 'bold' }}
                        >
                            <Input style={{ marginLeft: 289, width: 400 }} readOnly />
                        </Form.Item>

                        <DynamicField field={values.declaration} />

                        {['evolution'].map(k => (
                            <DynamicField key={k} field={values[k]} />
                        ))}

                        <Form.Item>
                            <Button
                                className="center-button"
                                type="primary"
                                htmlType="submit"
                            >
                                Valider
                            </Button>
                        </Form.Item>
                    </Form>
                </Form>
            </Card>
        </div>
    );
};

UpdateEvent.propTypes = {
    selectedEvent: PropTypes.object.isRequired,
    updateAdverseAsync: PropTypes.func.isRequired,
    selectAdverse: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    selectedEvent: state.adverse.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateAdverseAsync,
        selectAdverse,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(UpdateEvent);
