/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import { updatePatientAsync } from '../../redux/patients/actions';
import { createCallAsync } from '../../redux/call/actions';
import { getFullNameDynamicF } from '../../helpers/functions';

const ConfirmCallModal = ({
    open, handleClose, handleNext, updatePatientAsync, selectedPatient, createCallAsync,
}) => {
    const handleUnreachable = async () => {
        try {
            const countReachability = selectedPatient.countReachability + 1;
            updatePatientAsync(selectedPatient._id, { countReachability });
            createCallAsync({ patient: selectedPatient._id, isReachable: false });
            handleClose();
        } catch (err) {
            // console.log(err)
        }
    };


    return (
        <Modal
            okText="Oui"
            cancelText="Non"
            visible={open}
            onCancel={handleNext}
            title={selectedPatient ? `${getFullNameDynamicF(selectedPatient)}` : null}
            footer={[
                <Button key="ok" type="primary" onClick={handleNext}>Oui</Button>,
                <Button key="non" onClick={handleUnreachable}>Non</Button>,
            ]}>

            <p>Le patient est-il joignable ?</p>

        </Modal>
    );
};

ConfirmCallModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    createCallAsync: PropTypes.func.isRequired,
};


// const stateToProps = state => ({
// });

const dispatchToProps = dispatch => bindActionCreators(
    {
        updatePatientAsync,
        createCallAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(ConfirmCallModal);
