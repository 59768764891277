/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Tree, Form } from 'antd';
import { getFullNameDynamicF } from '../../helpers/functions';
import { prepareTree } from '../../helpers/patientInfo';

const ConsultPatientModal = ({ open, handleClose, selectedPatient }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedPatient) {
            Object.keys(selectedPatient).forEach((key) => {
                if (selectedPatient[key]) {
                    form.setFieldsValue({
                        [key]: selectedPatient[key].value,
                    });
                }
            });
        }
    }, [selectedPatient]);

    return (
        <Modal
            footer={null}
            visible={open}
            onCancel={handleClose}
            title={
                selectedPatient
                    ? `${getFullNameDynamicF(selectedPatient)}`
                    : null
            }
        >
            {selectedPatient && (
                <Form form={form}>
                    <Tree treeData={prepareTree(selectedPatient)} />
                </Form>
            )}
        </Modal>
    );
};

ConsultPatientModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
};

ConsultPatientModal.defaultProps = {
    selectedPatient: null,
};

export default ConsultPatientModal;
