/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    SET_CALLS_LIST,
    UPDATE_CALL,
    SELECT_AUDIT,
} from '../actions';

const initialState = {
    callsList: [],
    selectedAudit: null,
};

const calls = (state = initialState, action) => {
    switch (action.type) {
        case SET_CALLS_LIST:
            return { ...state, callsList: action.payload };
        case UPDATE_CALL:
            // eslint-disable-next-line no-case-declarations
            const callsList = [];
            state.callsList.map((patient) => {
                if (patient._id === action.payload._id) {
                    callsList.push(action.payload);
                } else callsList.push(patient);
            });
            return { ...state, callsList };
        case SELECT_AUDIT:
            return { ...state, selectedAudit: action.payload };

        default:
            return state;
    }
};

export default calls;
