/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
    Table, Button, Typography, Spin,
} from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatDateTimeFromIso } from '../../helpers/functions';
import {
    getAllOnboardedPatientsAsync,
    setPatientsList,
} from '../../redux/patients/actions';
import AddBlueCardCall from './bleue/add';
import AddRedCardCall from './rouge/add';
import AddOrangeCardCall from './orange/add';
import ConsultCalls from './consultCalls';
import { getAllCallsAsync } from '../../redux/call/actions';
import { getAllEventsByPatient } from '../../redux/adverse/actions';
import ConfirmCallModal from '../../components/ConfirmCallModal';
import AppointmentAdd from './orange/appointmentAdd';
// eslint-disable-next-line import/no-cycle
import { resolveCurrentUrl } from '../../app';

const { Text, Title } = Typography;
const prepareCardColor = () => {
    const currentURL = resolveCurrentUrl();
    switch (currentURL) {
        case 'red': return 'Violette';
        case 'orange': return 'Orange';
        case 'blue': return 'Bleue';
        default: return '';
    }
};


const displayAddForm = (props) => {
    switch (prepareCardColor()) {
        case 'Violette': return <AddRedCardCall {...props} />;
        case 'Orange': return <AddOrangeCardCall {...props} />;
        case 'Bleue': return <AddBlueCardCall {...props} />;
        default: return <div />;
    }
};

const PatientsList = ({
    patientsList,
    getAllOnboardedPatientsAsync,
    contentIsLoading,
    setPatientsList,
    getAllCallsAsync,
    getAllEventsByPatient,
    user,
}) => {
    const [selectedPatient, selectPatient] = useState();
    const [openAdd, setOpenAdd] = useState(false);
    const [openConsult, setOpenConsult] = useState(false);
    const [cardColor, setCardColor] = useState(prepareCardColor());

    const [openAppointment, setOpenAppointment] = useState(false);
    const [openConfirmCallModal, setOpenConfirmCallModal] = useState(false);


    useEffect(() => {
        if (!openConfirmCallModal && cardColor) getAllOnboardedPatientsAsync(`?cardColor=${cardColor}`);
        return () => {
            setPatientsList([]);
        };
    }, [openConfirmCallModal]);

    useEffect(() => {
        if (cardColor !== prepareCardColor()) {
            setCardColor(prepareCardColor());
        }
    }, []);


    const handleGoBack = () => {
        selectPatient(null);
        setOpenAdd(false);
        setOpenConsult(false);
    };

    const handleCloseModal = () => {
        setOpenConfirmCallModal(false);
        setOpenAdd(true);
    };


    const prepareTableActions = (record) => {
        const isDisabled = record.countReachability >= 7;
        return (
            <div>
                {user && user.type === 1 && (
                    <Button
                        disabled={isDisabled}
                        type="primary"
                        style={{ fontSize: '15px' }}
                        ghost
                        onClick={() => {
                            selectPatient(record);
                            setOpenConfirmCallModal(true);
                        }}
                        className="m-right-medium m-bottom-small"
                        icon={<PlusOutlined />}
                    >
                        Appel sortant
                    </Button>
                )}
                { user && user.type === 2 && cardColor === 'Orange' ? (
                    <Button
                        disabled={isDisabled}
                        type="primary"
                        ghost
                        onClick={() => {
                            selectPatient(record);
                            setOpenAppointment(true);
                        }}
                        className="m-right-medium m-small-bottom"
                        icon={<PlusOutlined />}
                    >
                        Ajouter un rendez-vous
                    </Button>

                ) : (
                    <Button
                        disabled={isDisabled}
                        type="primary"
                        style={{ fontSize: '14px' }}
                        ghost
                        onClick={() => {
                            setOpenConsult(true);
                            selectPatient(record);
                            getAllCallsAsync(`?patient=${record._id}`);
                            getAllEventsByPatient(record._id);
                        }}
                        icon={<EyeOutlined />}
                    >
                        Liste des appels
                    </Button>
                )}
            </div>
        );
    };

    const columns = [
        {
            title: <b>Identifiant</b>,
            key: 'name',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {record.customId ? record.customId : ''}
                </p>
            ),
        },
        {
            title: <b>Nom et Prénom </b>,
            key: 'name',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {`${record.lastName.value} ${record.firstName.value}`}
                </p>
            ),
        },
        {
            title: <b>Carte</b>,
            key: 'card',
            render: (_, record) => (
                <Text
                    className={record.cardColor}
                    style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}
                >
                    {record.cardColor}
                </Text>
            ),
        },
        {
            title: <b> Date de création </b>,
            key: 'date',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {formatDateTimeFromIso(record.createdAt)}
                </p>
            ),
        },
        {
            title: <b> Prochain appel </b>,
            key: 'nextCall',
            render: (_, record) => (
                <p style={{ opacity: record.countReachability >= 7 ? 0.45 : 1 }}>
                    {formatDateTimeFromIso(record.nextCall)}
                </p>
            ),
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            render: (_, record) => prepareTableActions(record),
            align: 'center',
        },
    ];


    if (selectedPatient) {
        if (openAdd) {
            return displayAddForm({ handleGoBack, selectedPatient });
        }
        if (openConsult) {
            return (
                <ConsultCalls
                    selectedPatient={selectedPatient}
                    handleGoBack={handleGoBack}
                />
            );
        }
    }

    if (openConfirmCallModal) {
        return (
            <ConfirmCallModal
                open={openConfirmCallModal}
                selectedPatient={selectedPatient}
                handleNext={handleCloseModal}
                handleClose={() => {
                    setOpenConfirmCallModal(false);
                }}
            />
        );
    }
    if (columns.length === 6) {
        if (user && user.type === 2) {
            columns.splice(5, 0, {
                title: <b> Date du rendez-vous </b>,
                key: 'rdv',
                render: (_, record) => (
                    <p>
                        {' '}
                        {record.rdvDate}
                        {' '}
                    </p>
                ),
            });
        }
    }

    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                <Title style={{ marginBottom: 30 }} level={5}>
                    Liste des patients carte&nbsp;
                    {prepareCardColor().toLowerCase()}
                </Title>
                <Table
                    className="m-top-medium"
                    columns={columns}
                    dataSource={patientsList}
                    locale={{ emptyText: 'Aucun patient' }}
                />
                <AppointmentAdd
                    open={openAppointment}
                    selectedPatient={selectedPatient}
                    handleClose={() => {
                        selectPatient(null);
                        setOpenAppointment(null);
                    }}
                />
            </div>
        </Spin>
    );
};

PatientsList.propTypes = {
    patientsList: PropTypes.array.isRequired,
    getAllOnboardedPatientsAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    getAllCallsAsync: PropTypes.func.isRequired,
    getAllEventsByPatient: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,

};

PatientsList.defaultProps = {};

const stateToProps = state => ({
    user: state.auth.user,
    patientsList: state.patients.patientsList,
    contentIsLoading: state.loading.contentIsLoading,
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllOnboardedPatientsAsync,
        setPatientsList,
        getAllCallsAsync,
        getAllEventsByPatient,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(PatientsList);
