import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import DynamicField from '../../../components/DynamicField';

const Cnam = ({ form, values }) => (
    <Form style={{ marginTop: 30 }} form={form}>
        <div style={{ padding: '0 34px', marginRight: '10%' }}>
            {[
                'applicationForm',
                'affiliation',
                'AP1form',
                'prescription',
                'letter',
                'nationalCard',
                'completeRecord',
                'agreement',
                'comprehension',
                'doseChangement',
                'receivedDrug',
            ].map(k => (
                <DynamicField key={k} field={values.cnam[k]} />
            ))}

        </div>
    </Form>
);

Cnam.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};

export default Cnam;
