/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import * as AdverseAPI from '../../../api/adverseEvent';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_ADVERSE_LIST = 'SET_ADVERSE_LIST';
export const ADD_ADVERSE = 'ADD_ADVERSE';
export const UPDATE_ADVERSE = 'UPDATE_ADVERSE';
export const SELECT_ADVERSE = 'SELECT_ADVERSE';

export const setAdverseList = value => ({
    type: SET_ADVERSE_LIST,
    payload: value,
});

export const addAdverse = value => ({
    type: ADD_ADVERSE,
    payload: value,
});

export const updateAdverse = value => ({
    type: UPDATE_ADVERSE,
    payload: value,
});

export const selectAdverse = value => ({
    type: SELECT_ADVERSE,
    payload: value,
});


export const getAllEventsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseAPI.getAll();
        dispatch(setAdverseList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const getAllEventsByPatient = patientId => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseAPI.getAllByPatient(patientId);
        dispatch(setAdverseList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createAdverseAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseAPI.create(body);
        dispatch(addAdverse(res.data));
        message.success('Évenement ajouté avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateAdverseAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseAPI.update(id, body);
        dispatch(updateAdverse(res.data));
        dispatch(selectAdverse(null));
        message.success('Modification effectuée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectEventAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseAPI.getById(id);
        dispatch(selectAdverse(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
