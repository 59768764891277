/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Avatar, List, Input, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const { Search } = Input;
const { Title } = Typography;

const filter = (query, data) => {
    const newQuery = query
        .replace(/[^a-z0-9]/gi, '')
        .split('')
        .join('.*');
    return data.filter((item) => {
        const regex = new RegExp(newQuery, 'i');
        return (
            regex.test(item.firstName.value) || regex.test(item.lastName.value)
        );
    });
};

const PatientsList = ({ patientsList, title, onSelect }) => {
    const [currentItems, setCurrentItems] = useState([]);

    const handleSearch = (searchValue) => {
        const filtredData = filter(searchValue, patientsList);
        setCurrentItems(filtredData);
    };


    useEffect(() => {
        if (patientsList.length) {
            setCurrentItems(patientsList);
        }
    }, [patientsList]);


    return (
        <div>
            <Search
                placeholder="Chercher par nom ou prénom"
                allowClear
                className="m-bottom-medium"
                style={{ maxWidth: '50%' }}
                onChange={e => handleSearch(e.target.value)}
            />
            <Title level={4} className="center-text m-top-medium">
                {title}
            </Title>
            <List
                locale={{ emptyText: 'Aucun patient' }}
                itemLayout="horizontal"
                dataSource={currentItems}
                renderItem={item => (
                    <List.Item className="patient-list-item " key={item._id} onClick={() => onSelect(item)}>
                        <List.Item.Meta
                            avatar={<Avatar icon={<UserOutlined />} />}
                            title={`${item.lastName.value} ${item.firstName.value}`}
                        />
                    </List.Item>
                )}
            />
        </div>

    );
};


PatientsList.propTypes = {
    title: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
};


const stateToProps = state => ({
    patientsList: state.patients.patientsList,
});


export default connect(stateToProps)(PatientsList);
