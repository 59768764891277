import { SET_USER } from '../actions';

const initialState = {
    user: null,
    // {
    //     firstName: 'Firas',
    //     lastName: 'Medecin',
    //     _id: '13a54a6f1afa',
    // },
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return { user: action.payload };
        default:
            return state;
    }
};

export default auth;
