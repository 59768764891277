/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import DynamicField from '../../../components/DynamicField';

const DoctorInfo = ({ form, values }) => (
    <Form style={{ marginTop: 30 }} form={form}>
        <div style={{ padding: '0 34px', marginRight: '10%' }}>
            {['firstName', 'lastName', 'place', 'address', 'email', 'phoneNumber'].map(k => (
                <DynamicField key={k} field={values.doctor[k]} />
            ))}
        </div>
    </Form>
);

DoctorInfo.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};


export default DoctorInfo;
