/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */

import React, { useState } from 'react';
import {
    Steps, Card, Spin, Button, Form, Divider, message,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { structureFields } from '../../../helpers/functions';
import { createPatientAsync } from '../../../redux/patients/actions';
import Identification from './identification';
import emptyValues from './emptyValues';
import CardPicker from '../../../components/CardsPicker';
import DoctorInfo from './doctorInfo';
import '../styles.css';
import DynamicField from '../../../components/DynamicField';
import HeadBreadCrumb from '../../../components/HeadBreadCrumb';

const { Step } = Steps;

const AddPatient = ({ contentIsLoading, createPatientAsync, handleGoBack }) => {
    window.onbeforeunload = function () { return 'Etes vous sûr(e) de vouloir quitter ?'; };
    const [current, setCurrent] = useState(0);
    const [selectedCard, selectCard] = useState();
    const [values, setValues] = useState(emptyValues);
    const [identificationForm] = Form.useForm();
    const [cardsForm] = Form.useForm();
    const [doctorForm] = Form.useForm();

    const handleSelectCard = (cardColor) => {
        cardsForm.setFieldsValue({ cardColor });
        selectCard(cardColor);
    };

    const showStep = () => {
        switch (current) {
            case 0: return <Identification values={values} form={identificationForm} />;
            case 1: return (
                <Form form={cardsForm}>
                    <DynamicField field={values.initialConsent} />
                    <Divider />
                    <CardPicker onSelect={handleSelectCard} />
                </Form>
            );
            case 2: return <DoctorInfo values={values} form={doctorForm} />;
            default:
                return <div />;
        }
    };

    const handleNextStep = async () => {
        try {
            let vals;
            switch (current) {
                case 0:
                    vals = await identificationForm.validateFields();
                    setValues({
                        ...values,
                        ...structureFields(values, vals),
                    });
                    setCurrent(current + 1);
                    break;
                case 1:
                    if (!selectedCard) {
                        return message.error('Veuillez choisir une carte de liaison');
                    }
                    vals = await cardsForm.validateFields();
                    setValues({
                        ...values,
                        ...structureFields(values, vals),
                    });
                    setCurrent(current + 1);
                    break;
                case 2:
                    vals = await doctorForm.validateFields();
                    const body = {
                        ...values,
                        doctor: {
                            ...structureFields(values.doctor, vals),
                        },
                        consent: null,
                        cardColor: selectedCard,
                    };
                    createPatientAsync(body);
                    identificationForm.resetFields();
                    doctorForm.resetFields();
                    handleGoBack();
                    break;
                default:
                    break;
            }
        } catch (err) {
            // console.log(err)
        }
    };

    return (
        <div className="section-content">
            <Spin spinning={contentIsLoading}>
                <HeadBreadCrumb
                    firstSectionName="Liste des patients"
                    secondSectionName="Ajout d'un patient"
                    handleGoBack={handleGoBack}
                />
                <Card>
                    <Steps
                        current={current}
                        onChange={setCurrent}
                        size="small"
                        className="m-bottom-medium"
                    >
                        <Step title="Identification" />
                        <Step disabled={current <= 1} title="Carte de liaison" />
                        <Step disabled={current <= 1} title="Médecin traitant" />
                    </Steps>
                    <div className="m-top-medium" style={{ padding: 24 }}>
                        {showStep()}
                    </div>
                    <Button
                        onClick={handleNextStep}
                        type="primary"
                        className="m-top-medium m-bottom-medium center-button"
                    >
                        {current < 2 ? 'Suivant' : 'Terminer'}
                    </Button>
                </Card>
            </Spin>
        </div>
    );
};

AddPatient.propTypes = {
    contentIsLoading: PropTypes.bool.isRequired,
    createPatientAsync: PropTypes.func.isRequired,
    handleGoBack: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators({
    createPatientAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(AddPatient);
