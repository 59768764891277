/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Tooltip } from 'antd';
import {
    CheckCircleOutlined,
    PauseCircleOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    CloseCircleOutlined,
    HistoryOutlined,
    PauseCircleFilled,
    CheckCircleFilled,
    QuestionCircleFilled,
} from '@ant-design/icons';
import { selectAuditAsync } from '../../redux/call/actions';
import './styles.css';


const FieldActions = ({
    user,
    onUpdate,
    field,
    fieldFamily,
    selectAuditAsync,
    call,
    extraFieldIndex,
}) => {
    if (!field) return <div />;

    const nothingSelected = !call;
    if (user.type === 1) {
        return (
            <div>
                {field.readOnly || nothingSelected || field.readOnly !== false ? (
                    <Tooltip className="m-left-medium" title="Modifier ce champ">
                        <Button
                            type="text"
                            shape="circle"
                            disabled={nothingSelected || field.readOnly === false}
                            icon={<EditOutlined className="field-action-icon color-blue" />}
                            onClick={() => onUpdate(fieldFamily, field.name, 'readOnly', false, extraFieldIndex)}
                        />
                    </Tooltip>
                ) : (
                    <div>
                        <Tooltip className="m-left-medium" title="Enregistrer">
                            <Button
                                type="text"
                                shape="circle"
                                disabled={nothingSelected}
                                icon={<CheckCircleOutlined className="field-action-icon color-green" />}
                                onClick={() => onUpdate(fieldFamily, field.name, 'value', false, extraFieldIndex)}
                            />
                        </Tooltip>
                        <Tooltip className="m-left-medium" title="Annuler">
                            <Button
                                type="text"
                                shape="circle"
                                disabled={nothingSelected}
                                icon={<CloseCircleOutlined className="field-action-icon color-red" />}
                                onClick={() => onUpdate(fieldFamily, field.name, 'cancel', false, extraFieldIndex)}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }

    const handleClickAudit = (extraFieldIndex) => {
        let query;
        if (!isNaN(extraFieldIndex)) {
            query = `fieldFamily=${fieldFamily}&extraFieldName=${field.name[1]}&call=${call._id}`;
        } else {
            query = `fieldFamily=${fieldFamily}&fieldName=${field.name}&call=${call._id}`;
        }
        selectAuditAsync(call._id, query);
    };

    return (
        <div className="flex m-left-medium">
            <Tooltip className="m-left-medium" title={field.sdv ? 'SDV' : 'Check SDV?'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    icon={field.sdv ? <CheckCircleFilled className="field-action-icon color-green" /> : <CheckCircleOutlined className="field-action-icon color-green" />}
                    onClick={() => onUpdate(fieldFamily, field.name, 'sdv', !field.sdv, extraFieldIndex)}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title={field.frozen ? 'Unfreeze' : 'Freeze?'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    icon={field.frozen ? <PauseCircleFilled className="field-action-icon color-blue" /> : <PauseCircleOutlined className="field-action-icon color-blue" />}
                    onClick={() => onUpdate(fieldFamily, field.name, 'frozen', !field.frozen, extraFieldIndex)}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title="Audit trial">
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    onClick={() => handleClickAudit(extraFieldIndex)}
                    icon={<HistoryOutlined className="field-action-icon color-yellow" />}
                />
            </Tooltip>
            <Tooltip className="m-left-medium" title={field.query ? 'Consulter Query' : 'Query'}>
                <Button
                    type="text"
                    shape="circle"
                    disabled={nothingSelected}
                    onClick={() => onUpdate(fieldFamily, field.name, 'query', extraFieldIndex, extraFieldIndex)}
                    icon={field.query ? <QuestionCircleFilled className="field-action-icon color-red" /> : <QuestionCircleOutlined className="field-action-icon color-red" />}
                />
            </Tooltip>
        </div>
    );
};

FieldActions.propTypes = {
    user: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    fieldFamily: PropTypes.string.isRequired,
    selectAuditAsync: PropTypes.func.isRequired,
    call: PropTypes.object,
    extraFieldIndex: PropTypes.number,
};

FieldActions.defaultProps = {
    call: null,
    extraFieldIndex: 'w',
};

const stateToProps = state => ({
    user: state.auth.user,
});


const dispatchToProps = dispatch => bindActionCreators(
    {
        selectAuditAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(FieldActions);
