/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Steps, Button, Card, Typography, Divider,
} from 'antd';
import emptyValues from '../add/emptyValues';
import { getFullNameDynamicF, structureFields } from '../../../helpers/functions';
import Treatment from './treatment';
import CnamForm from './cnam';
import DynamicField from '../../../components/DynamicField';
import { createCallAsync } from '../../../redux/call/actions';
import HeadBreadCrumb from '../../../components/HeadBreadCrumb';
import AddDescriptionToPatient from '../commonModal/AddDescriptionToPatient';
import { addDescription } from '../commonModal/commonMethod';

const { Step } = Steps;
const { Title } = Typography;


const AddCall = ({
    createCallAsync, selectedPatient, handleGoBack, authId,
}) => {
    window.onbeforeunload = function () { return 'Etes vous sûr(e) de vouloir quitter ?'; };
    const [current, setCurrent] = useState(0);
    const [values, setValues] = useState(emptyValues);
    const [treatmentForm] = Form.useForm();
    const [cnamForm] = Form.useForm();
    const [jakaviForm] = Form.useForm();
    const [diseaseForm] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const showStep = () => {
        switch (current) {
            case 0: return <Treatment values={values} form={treatmentForm} />;
            case 1: return <CnamForm values={values} form={cnamForm} />;
            case 2: return (
                <div>
                    <Title className="m-bottom-medium" level={5}>Workshop Jakavi</Title>
                    <Form form={jakaviForm}>
                        {['invitation', 'date', 'place'].map(k => (
                            <DynamicField key={k} field={values.workshop[k]} />
                        ))}
                    </Form>
                    <Divider />
                    <Title className="m-bottom-medium" level={5}>Workshop Maladie</Title>
                    <Form form={diseaseForm}>
                        {['invitation', 'date', 'place'].map(k => (
                            <DynamicField key={k} field={values.workshop[k]} />
                        ))}
                    </Form>
                </div>
            );
            default:
                return <div />;
        }
    };

    const handleNextStep = async () => {
        try {
            let vals;
            let jakavi;
            let disease;
            switch (current) {
                case 0:
                    vals = await treatmentForm.validateFields();
                    setValues({
                        ...values,
                        treatment: structureFields(values.treatment, vals),
                    });

                    setCurrent(current + 1);

                    break;
                case 1:
                    vals = await cnamForm.validateFields();
                    setValues({
                        ...values,
                        cnam: structureFields(values.cnam, vals),
                    });
                    setCurrent(current + 1);
                    break;
                case 2:
                    jakavi = await jakaviForm.validateFields();
                    disease = await diseaseForm.validateFields();
                    createCallAsync({
                        ...values,
                        workshopJakavi: structureFields(values.workshop, jakavi),
                        workshopDisease: structureFields(values.workshop, disease),
                        patient: selectedPatient._id,
                        isReachable: true,
                    });
                    handleGoBack();
                    break;
                default:
                    break;
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const onCreate = (values) => {
        addDescription(values, createCallAsync, setVisible, authId, selectedPatient);
    };

    return (
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Appels patient"
                secondSectionName={selectedPatient ? getFullNameDynamicF(selectedPatient) : 'Patient'}
                thirdSectionName="Ajout d'un appel"
                handleGoBack={handleGoBack}
            />
            <div>
                <Button type="primary" onClick={() => setVisible(true)}>Ajouter seulement une description</Button>
                <AddDescriptionToPatient
                    visible={visible}
                    onCancel={() => {
                        setVisible(false);
                    }
                    }
                    onCreate={onCreate}
                    patient={selectedPatient}
                />
            </div>
            <Card>
                <Steps
                    current={current}
                    onChange={setCurrent}
                    size="small"
                    className="m-bottom-medium"
                >
                    <Step title="Traitement" />
                    <Step disabled={current <= 1} title="Prise en charge CNAM et remboursements" />
                    <Step disabled={current <= 1} title="Workshops" />
                </Steps>
                <div className="m-top-medium" style={{ padding: 24 }}>
                    {showStep()}
                </div>
                <Button
                    onClick={handleNextStep}
                    type="primary"
                    className="m-top-medium m-bottom-medium center-button"
                >
                    {current < 2 ? 'Suivant' : 'Terminer'}
                </Button>
            </Card>
        </div>
    );
};

AddCall.propTypes = {
    createCallAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    handleGoBack: PropTypes.func.isRequired,
};


const stateToProps = state => ({
    authId: state.auth.user._id,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        createCallAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddCall);
