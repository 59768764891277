import axios from 'axios';
import { API_AUTH, API_REQ_HEADER } from './config';

const getStatus = async () => {
    const res = await axios.get(API_AUTH, API_REQ_HEADER);
    return res.data;
};

const register = async (body) => {
    const res = await axios.post(`${API_AUTH}/register`, body, API_REQ_HEADER);
    return res.data;
};

const login = async (credentials) => {
    const res = await axios.post(`${API_AUTH}/login`, credentials, API_REQ_HEADER);

    return res.data;
};

const logout = async () => {
    const res = await axios.post(`${API_AUTH}/logout`, null, API_REQ_HEADER);

    return res.data;
};

const update = async (body) => {
    const res = await axios.put(`${API_AUTH}/profile`, body, API_REQ_HEADER);

    return res.data;
};


const updateMail = async (body) => {
    const res = await axios.put(`${API_AUTH}/mail`, body, API_REQ_HEADER);
    return res.data;
};

const generatEmailVerification = async () => {
    const res = await axios.get(`${API_AUTH}/mail`, API_REQ_HEADER);
    return res.data;
};


export {
    getStatus, register, login, logout, update,
    updateMail, generatEmailVerification,
};
