/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Button, Card, Typography,
} from 'antd';
import emptyValues from '../add/emptyValues';
import { getFullNameDynamicF, structureFields } from '../../../helpers/functions';
import DynamicField from '../../../components/DynamicField';
import { createCallAsync } from '../../../redux/call/actions';
import HeadBreadCrumb from '../../../components/HeadBreadCrumb';
import AddDescriptionToPatient from '../commonModal/AddDescriptionToPatient';
import { addDescription } from '../commonModal/commonMethod';

const { Title } = Typography;

const AddCall = ({
    createCallAsync, selectedPatient, handleGoBack, authId,
}) => {
    window.onbeforeunload = function () { return 'Etes vous sûr(e) de vouloir quitter ?'; };
    const [values] = useState(emptyValues);
    const [diseaseForm] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const onCreate = (values) => {
        addDescription(values, createCallAsync, setVisible, authId, selectedPatient);
    };
    const onFinish = async () => {
        try {
            const disease = await diseaseForm.validateFields();
            createCallAsync({
                workshopDisease: structureFields(values.workshop, disease),
                patient: selectedPatient._id,
                isReachable: true,
            });
            handleGoBack();
        } catch (err) {
            // nvm
        }
    };

    return (
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Appels patient"
                secondSectionName={selectedPatient ? getFullNameDynamicF(selectedPatient) : 'Patient'}
                thirdSectionName="Ajout d'un appel"
                handleGoBack={handleGoBack}
            />
            <div>
                <Button type="primary" onClick={() => setVisible(true)}>Ajouter seulement une description</Button>
                <AddDescriptionToPatient
                    visible={visible}
                    onCancel={() => {
                        setVisible(false);
                    }
                    }
                    onCreate={onCreate}
                    patient={selectedPatient}
                />
            </div>
            <Card>

                <div className="m-top-medium" style={{ padding: 24 }}>
                    <Title className="m-bottom-medium" level={5}>Workshop Maladie</Title>
                    <Form form={diseaseForm}>
                        {['invitation', 'date', 'place'].map(k => (
                            <DynamicField key={k} field={values.workshop[k]} />
                        ))}
                    </Form>
                </div>
                <Button
                    onClick={onFinish}
                    type="primary"
                    className="m-top-medium m-bottom-medium center-button"
                >
                    Terminer
                </Button>
            </Card>
        </div>
    );
};

AddCall.propTypes = {
    createCallAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    handleGoBack: PropTypes.func.isRequired,
};


const stateToProps = state => ({
    authId: state.auth.user._id,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        createCallAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddCall);
