import React, { useState } from 'react';
import {
    Layout, Menu, Popover, Avatar,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LogoutOutlined } from '@ant-design/icons';
import { disconnectAsync } from '../../redux/auth/actions';
import { getFullName, getUserInitials } from '../../helpers/functions';
import './styles.css';

const { Header } = Layout;


const AppHeader = ({ user, disconnectAsync }) => {
    const [openPop, setOpenPop] = useState(false);
    const PopoverContent = (
        <Menu>
            <Menu.Item onClick={disconnectAsync} icon={<LogoutOutlined />} key="0">
                Se déconnecter
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="layout-bg header-top">
            <div>
                {user ? (
                    <Popover
                        content={PopoverContent}
                        trigger="hover"
                        visible={openPop}
                        onVisibleChange={setOpenPop}
                    >
                        <div
                            className="flex"
                            style={{ cursor: 'pointer', alignItems: 'center' }}
                        >
                            <Avatar className="m-right-small">
                                {getUserInitials(user)}
                            </Avatar>
                            <span>
                                {getFullName(user)}
                            </span>
                        </div>
                    </Popover>
                ) : null}
            </div>
        </Header>
    );
};

AppHeader.propTypes = {
    user: PropTypes.object,
    disconnectAsync: PropTypes.func.isRequired,
};

AppHeader.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators({ disconnectAsync }, dispatch);

export default connect(stateToProps, dispatchToProps)(AppHeader);
