import React from 'react';
import { Breadcrumb, Button } from 'antd';
import PropTypes from 'prop-types';

const HeadBreadCrumb = ({
    firstSectionName, secondSectionName, handleGoBack, thirdSectionName,
}) => (
    <Breadcrumb style={{ marginBottom: 15 }}>
        <Breadcrumb.Item onClick={() => handleGoBack()}>
            <Button style={{ padding: 0 }} type="link">
                {firstSectionName}
            </Button>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{secondSectionName}</Breadcrumb.Item>
        {thirdSectionName ? (
            <Breadcrumb.Item>{thirdSectionName}</Breadcrumb.Item>
        ) : null}
    </Breadcrumb>
);


HeadBreadCrumb.propTypes = {
    handleGoBack: PropTypes.func.isRequired,
    firstSectionName: PropTypes.string.isRequired,
    secondSectionName: PropTypes.string.isRequired,
    thirdSectionName: PropTypes.string,
};

HeadBreadCrumb.defaultProps = {
    thirdSectionName: null,
};


export default (HeadBreadCrumb);
