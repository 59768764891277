/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import loading from './loading/reducer';
import auth from './auth/reducer';
import patients from './patients/reducer';
import calls from './call/reducer';
import adverse from './adverse/reducer';
import queries from './query/reducer';


export default createStore(
    combineReducers({
        loading,
        auth,
        patients,
        calls,
        adverse,
        queries,
    }),
    composeWithDevTools(applyMiddleware(thunk)),
);
/* eslint-enable */
