/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Form, Input } from 'antd';
import { updatePatientAsync } from '../../../redux/patients/actions';
import { getFullNameDynamicF } from '../../../helpers/functions';
import FORM_VALIDATORS from '../../../helpers/formRules';


const AddAppointment = ({
    open, handleClose, updatePatientAsync, selectedPatient,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) form.resetFields();
    }, [open]);

    const handleCreate = async () => {
        try {
            await form.validateFields();
            const body = {
                rdvDate: `${form.getFieldValue('rdvDate')} à ${
                    form.getFieldValue('timeDate')}`,
            };
            updatePatientAsync(selectedPatient._id, body);
            handleClose();
        } catch (err) {
            // nada
        }
    };


    return (
        <Modal
            okText="Valider"
            cancelText="Annuler"
            visible={open}
            onCancel={handleClose}
            title={selectedPatient ? `${getFullNameDynamicF(selectedPatient)}` : null}
            onOk={handleCreate}>
            <Form form={form}>
                <Form.Item name="rdvDate" label="Date" rules={[FORM_VALIDATORS.required, FORM_VALIDATORS.validDate]}>
                    <Input placeholder="eg. 01/01/2000" />
                </Form.Item>

                <Form.Item name="timeDate" label="Heure" rules={[FORM_VALIDATORS.required, FORM_VALIDATORS.time]}>
                    <Input placeholder="eg. 00:00" />
                </Form.Item>

            </Form>
        </Modal>
    );
};

AddAppointment.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
};


// const stateToProps = state => ({
// });

const dispatchToProps = dispatch => bindActionCreators(
    {
        updatePatientAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(AddAppointment);
