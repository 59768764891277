/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Card, Steps, Form, Spin, Input, Button, Table, Typography,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getAllOnboardedPatientsAsync,
    setPatientsList,
} from '../../redux/patients/actions';
import PatientsList from './patientsList';
import FORM_VALIDATORS from '../../helpers/formRules';
import './styles.css';
import { getAllEventsAsync, selectEventAsync } from '../../redux/adverse/actions';
import { formatDateTimeFromIso, getFullNameDynamicF } from '../../helpers/functions';
import { createCallAsync } from '../../redux/call/actions';

// TODO Cleaning DOc
// REVIEW put this component on a seperate file
const { Step } = Steps;
const { Text } = Typography;

const IncomingCallAdd = ({ form, onFinish }) => (
    <Form style={{ marginTop: 30 }} form={form} onFinish={onFinish}>
        <div style={{ marginLeft: 34 }}>
            <Form.Item
                rules={[FORM_VALIDATORS.validDate]}
                name="date"
                label="Date"
            >
                <Input placeholder="eg: 01/01/2000" />
            </Form.Item>
            <Form.Item
                name="description"
                label="Décrivez l'appel"
            >
                <Input.TextArea />
            </Form.Item>
            <Form.Item>
                <Button type="primary" className="center-button" htmlType="submit">
                    Ajouter
                </Button>
            </Form.Item>


        </div>
    </Form>

);

IncomingCallAdd.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
};

const IncomingCall = ({
    user,
    contentIsLoading,
    getAllOnboardedPatientsAsync,
    setPatientsList,
    patientsList,
    createCallAsync,
    eventsList,
    getAllEventsAsync,
    selectEventAsync,
}) => {
    const [current, setCurrent] = useState(0);
    const [patient, selectPatient] = useState();
    const [form] = Form.useForm();
    const [values] = useState();

    useEffect(() => {
        if (user) {
            if (user.type === 1) {
                getAllOnboardedPatientsAsync('');
            } else {
                getAllEventsAsync();
            }
        }
        return () => {
            setPatientsList([]);
        };
    }, [user]);

    const onFinish = (values) => {
        createCallAsync({ ...values, patient: patient._id, isIncoming: true });
        form.resetFields();
        selectPatient(null);
        setCurrent(0);
    };

    const handleSelect = (p) => {
        selectPatient(p);
        setCurrent(1);
    };

    const showStep = () => {
        if (current === 0) {
            return <PatientsList data={patientsList} onSelect={handleSelect} />;
        }
        return <IncomingCallAdd form={form} values={values} onFinish={onFinish} />;
    };

    const columns = [
        {
            title: <b>Nom et Prénom</b>,
            key: 'name',
            render: (_, record) => (
                <p>{`${getFullNameDynamicF(record.patient)}`}</p>
            ),
        },
        {
            title: <b>Carte</b>,
            key: 'card',
            render: (_, record) => (
                <Text className={record.patient.cardColor}>{record.patient.cardColor}</Text>
            ),
        },
        {
            title: <b> Date de déclaration de l&apos;événement </b>,
            key: 'date',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            render: (_, record) => (
                <div className="flex">
                    <Button
                        type="primary"
                        ghost
                        className="m-left-medium"
                        onClick={() => {
                            selectEventAsync(record._id);
                        }}
                        icon={<SettingOutlined />}
                    >
                        Mettre a jour
                    </Button>
                </div>
            ),
        },
    ];


    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                <Title level={5} style={{ marginBottom: '20px' }}>Ajout d&apos;un appel entrant spontanné</Title>
                {user && user.type === 1 ? (
                    <Card>
                        <Steps size="small" current={current} onChange={setCurrent}>
                            <Step title="Choisir un patient" />
                            <Step disabled={!patient} title="Description" />
                        </Steps>
                        <div style={{ margin: 'auto', marginTop: 30, padding: 24 }}>
                            {showStep()}
                        </div>
                    </Card>
                ) : (
                    <Table
                        className="m-top-medium"
                        columns={columns}
                        dataSource={eventsList}
                        locale={{ emptyText: 'Aucun événement' }}
                    />
                )}
            </div>
        </Spin>
    );
};

IncomingCall.propTypes = {
    contentIsLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    getAllOnboardedPatientsAsync: PropTypes.func.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
    eventsList: PropTypes.array.isRequired,
    createCallAsync: PropTypes.func.isRequired,
    getAllEventsAsync: PropTypes.func.isRequired,
    selectEventAsync: PropTypes.func.isRequired,
};

IncomingCall.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    patientsList: state.patients.patientsList,
    eventsList: state.adverse.events,
    selectedEvent: state.adverse.selectedEvent,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllOnboardedPatientsAsync,
        setPatientsList,
        createCallAsync,
        getAllEventsAsync,
        selectEventAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(IncomingCall);
