import axios from 'axios';
import { API_CALLS, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_CALLS}/`, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CALLS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getAll = async (query) => {
    const res = await axios.get(`${API_CALLS}/${query}`, API_REQ_HEADER);
    return res.data;
};


const update = async (id, body) => {
    const res = await axios.put(`${API_CALLS}/${id}`, body, API_REQ_HEADER);
    return res.data;
};


const getAuditTrial = async (id, query) => {
    const res = await axios.get(`${API_CALLS}/audit/${id}?${query}`, API_REQ_HEADER);
    return res.data;
};


export {
    create, getById, getAll, update, getAuditTrial,
};
