/* eslint-disable no-underscore-dangle */
import React from 'react';
import { formatDateTimeFromIso, mapFieldsToTree } from './functions';

const mapFieldFamilyName = {
    patient: 'Patient',
    treatment: 'Traitement',
    workshopDisease: 'Workshop Maladie',
    workshopJakavi: 'Workshop Jakavi',
    cnam: 'Prise en charge CNAM',
    doctor: 'Médecin',
    description: 'Description',
};


// eslint-disable-next-line import/prefer-default-export
export const prepareTree = (fieldFamily, call, onUpdate) => [
    {
        title: (
            <>
                <strong>
                    {mapFieldFamilyName[fieldFamily.name]}
                </strong>
            </>
        ),
        key: `${call._id}-${fieldFamily.name}`,
        children: mapFieldsToTree(fieldFamily, call, onUpdate),
    },
];

export const prepareTreeEvents = event => [
    {
        title: (
            <>
                <strong>
                    Événement indésirable le
                    {formatDateTimeFromIso(event.createdAt)}
                </strong>
            </>
        ),
        key: event.createdAt,
        children: [],
    },
];
