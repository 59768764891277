/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { dateTimeFromTimestamp } from '../../helpers/functions';
import './styles.css';


const SentText = ({ isUser, date }) => {
    if (isUser) {
        return (
            <div className="message--sent">
                <span>{dateTimeFromTimestamp(date)}</span>
            </div>
        );
    }
    return <span className="message--sent-date">{dateTimeFromTimestamp(date)}</span>;
};

SentText.propTypes = {
    isUser: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
};

const getClassNames = isUser => classnames('message', {
    'message-user-receiver': isUser,
});

const getTextClassNames = isUser => classnames({
    'message-text-contact': !isUser,
    'message-text-user': isUser,
});

const Messages = ({ messages, user }) => messages.map((message, index) => {
    const { content, time } = message;
    const isUser = message.sender === user._id;
    const key = `msg-${index}`;

    return (
        <div key={key} className={getClassNames(isUser)} id={key}>
            <Avatar style={{ backgroundColor: isUser ? '#1890ff' : '#f0f2f5' }} icon={<UserOutlined />} />
            <SentText isUser={isUser} date={time} />
            <p className={getTextClassNames(isUser)}>
                {content}
            </p>
        </div>
    );
});

Messages.propTypes = {
    user: PropTypes.object.isRequired,
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string,
            time: PropTypes.string,
            sender: PropTypes.string,
        }),
    ),
    selectedUser: PropTypes.object,
};

Messages.defaultProps = {
    messages: [],
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps, null)(Messages);
