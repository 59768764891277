/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Form } from 'antd';
import emptyValues from './add/emptyValues';
import { updatePatientAsync } from '../../redux/patients/actions';
import { getFullNameDynamicF, structureFields } from '../../helpers/functions';
import DynamicField from '../../components/DynamicField';

const ConsentModal = ({
    open,
    handleClose,
    updatePatientAsync,
    selectedPatient,
}) => {
    const [form] = Form.useForm();
    const [values] = useState(emptyValues);

    useEffect(() => {
        if (!open) form.resetFields();
    }, [open]);

    const handleCreate = async () => {
        try {
            const vals = await form.validateFields();
            const consent = structureFields(values, { ...vals, signed: 'Oui' });
            updatePatientAsync(selectedPatient._id, { consent });
            handleClose();
        } catch (err) {
            // console.log(err)
        }
    };

    return (
        <Modal
            okText="Valider"
            cancelText="Annuler"
            visible={open}
            onCancel={handleClose}
            title={
                selectedPatient
                    ? `Consentement du patient ${getFullNameDynamicF(selectedPatient)}`
                    : null
            }
            onOk={handleCreate}
        >
            <Form form={form}>
                {['consentDate'].map(k => (
                    <DynamicField key={k} field={values.consent[k]} />
                ))}
            </Form>
        </Modal>
    );
};

ConsentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
};

// const stateToProps = state => ({
// });

const dispatchToProps = dispatch => bindActionCreators(
    {
        updatePatientAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(ConsentModal);
