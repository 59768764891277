/* eslint-disable import/prefer-default-export */
import React from 'react';
import { mapFieldsToTree } from './functions';


const prepareDoctor = (doctor) => {
    const {
        firstName, lastName, place, address, phoneNumber, email,
    } = doctor;

    return {
        title: (<strong>Médecin : &nbsp;</strong>),
        key: 'Doctor',
        children: [
            {
                title: (
                    <>
                        <strong>Nom : &nbsp;</strong>
                        {lastName.value}
                    </>
                ),
                key: 'doctorLast',
            },
            {
                title: (
                    <>
                        <strong>Prénom : &nbsp;</strong>
                        {firstName.value}
                    </>
                ),
                key: 'doctorFirst',

            },
            {
                title: (
                    <>
                        <strong>Lieu : &nbsp;</strong>
                        {place.value}
                    </>
                ),
                key: 'doctorPlace',

            },
            {
                title: (
                    <>
                        <strong>Adresse : &nbsp;</strong>
                        {address.value}
                    </>
                ),
                key: 'doctorAdr',

            },
            {
                title: (
                    <>
                        <strong>Téléphone : &nbsp;</strong>
                        {phoneNumber.value}
                    </>
                ),
                key: 'doctortlf',

            },
            {
                title: (
                    <>
                        <strong>Email : &nbsp;</strong>
                        {email.value}
                    </>
                ),
                key: 'doctorEmail',

            },
        ],
    };
};

export const prepareTree = (patient) => {
    const {
        _id, createdAt, updatedAt, doctor, consent, creator, __v, cardColor, ...rest
    } = patient;
    return [...mapFieldsToTree(rest), prepareDoctor(doctor)];
};
