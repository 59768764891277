/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Modal, Form, Input, Typography, message,
} from 'antd';
import { createQueryAsync } from '../../redux/query/actions';
import { getFullNameDynamicF } from '../../helpers/functions';
import FORM_VALIDATORS from '../../helpers/formRules';
import QueryMessages from '../QueryMessages';

const { Text } = Typography;

const QueryModal = ({
    open,
    field,
    handleClose,
    createQueryAsync,
    call,
    query,
    user,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) form.resetFields();
    }, [open]);

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            const name = Array.isArray(field.name) ? field.name[1] : field.name;
            const body = {
                message: {
                    content: values.message,
                    time: Date.now(),
                    sender: user._id,
                },
                patient: call.patient._id,
                fieldName: name,
                fieldFamily: field.fieldFamily,
                call: call._id,
                callCenterAgent: call.creator,
                field,
            };
            createQueryAsync(body);
            handleClose();
        } catch (error) {
            message.error('Veuillez remplir le champ description');
        }
    };

    if ((!field || !call) && !query) {
        return <div />;
    }

    return (
        <Modal
            okText="Valider"
            cancelText={query ? 'Fermer' : 'Annuler'}
            visible={open}
            onCancel={handleClose}
            cancelButtonProps={{
                style: { display: query ? 'none' : 'inline-block' },
            }}
            okButtonProps={{ style: { display: query ? 'none' : 'inline-block' } }}
            title={query ? 'Réponse à la Query' : 'Nouvelle Query'}
            onOk={handleCreate}
            width={800}
        >
            {query ? (
                <QueryMessages query={query} />
            ) : (
                <Form form={form}>
                    <div className="flex m-bottom-medium">
                        <Text strong>Patient : </Text>
            &nbsp;

                        {call ? getFullNameDynamicF(call.patient) : ''}
                    </div>
                    <div className="flex m-bottom-medium">
                        <Text strong>Nom du champ : </Text>
            &nbsp;
                        {field.label}
                    </div>
                    <div className="flex m-bottom-medium">
                        <Text strong>Valeur du champ : </Text>
            &nbsp;
                        {field.value}
                    </div>
                    <div className="flex-column m-bottom-medium">
                        <Text strong className="m-bottom-small">
                            Description :
                            {' '}
                        </Text>
                        <Form.Item
                            rules={[FORM_VALIDATORS.required]}
                            name="message"
                            noStyle
                        >
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </div>
                </Form>
            )}
        </Modal>
    );
};

QueryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createQueryAsync: PropTypes.func.isRequired,
    field: PropTypes.object,
    call: PropTypes.object,
    query: PropTypes.object,
    user: PropTypes.object.isRequired,
};

QueryModal.defaultProps = {
    field: null,
    call: null,
    query: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    query: state.queries.selectedQuery,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        createQueryAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(QueryModal);
