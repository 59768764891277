/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-plusplus */


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { connect } from 'react-redux';
import DynamicField from '../../../components/DynamicField';

const Identification = ({ form, values, patientsList }) => {
    const [showCustomId, setShowCustomId] = useState(false);
    const [customId, setCustomId] = useState('');
    const [patients, setPatients] = useState(patientsList);

    useEffect(() => {
        if (!patients.length) setCustomId('001');
        if (patients.length) {
            const lastObject = patients.reduce((r, a) => (r.createdAt > a.createdAt ? r : a));
            let customId = lastObject.customId
                ? lastObject.customId.substr(lastObject.customId.lastIndexOf('-') + 1)
                : '0';
            parseInt(customId);
            customId++;
            const padStart = customId.toString().padStart(5, 0);
            setCustomId(padStart);
            setCustomId(state => state);
        }
    }, [patients, patientsList]);

    const handleValuesChange = (val) => {
        if (val.gender) setShowCustomId(true);
    };

    return (
        <Form
            style={{ marginTop: 30 }}
            form={form}
            onValuesChange={handleValuesChange}
        >
            <div style={{ padding: '0 34px', marginRight: '10%' }}>
                {[
                    'firstName',
                    'lastName',
                    'address',
                    'city',
                    'phoneNumber',
                    'phoneNumber2',
                    'email',
                    'birthDate',
                    'gender',
                ].map(k => (
                    <DynamicField key={k} field={values[k]} />
                ))}
            </div>
            {showCustomId ? (
                <strong>
                    Identifiant du patient: KTN-
                    {customId}
                </strong>
            ) : null}
        </Form>
    );
};

Identification.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    patientsList: PropTypes.array.isRequired,
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
});

export default connect(stateToProps)(Identification);
