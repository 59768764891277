/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    SET_ADVERSE_LIST,
    ADD_ADVERSE,
    UPDATE_ADVERSE,
    SELECT_ADVERSE,
} from '../actions';

const initialState = {
    events: [],
    selectedEvent: null,
};

const events = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADVERSE_LIST:
            return { ...state, events: action.payload };
        case SELECT_ADVERSE:
            return { ...state, selectedEvent: action.payload };
        case ADD_ADVERSE:
            return {
                ...state,
                events: [action.payload, ...state.events],
            };

        case UPDATE_ADVERSE:
            // eslint-disable-next-line no-case-declarations
            const events = [];
            state.events.map((event) => {
                if (event._id === action.payload._id) {
                    events.push(action.payload);
                } else events.push(event);
            });
            return { ...state, events };

        default:
            return state;
    }
};

export default events;
