/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    Card, Form, Input, Button, Collapse, Typography,
    message,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Messages from './messages';
import { pushQueryMessageAsync } from '../../redux/query/actions';
import { getFullNameDynamicF } from '../../helpers/functions';
import FORM_VALIDATORS from '../../helpers/formRules';

const { Panel } = Collapse;
const { Text } = Typography;

const QueryMessages = ({ query, pushQueryMessageAsync, user }) => {
    const [form] = Form.useForm();

    const onFinish = async ({ messageToSend }) => {
        try {
            await form.validateFields();
            pushQueryMessageAsync(query._id, {
                message: {
                    content: messageToSend,
                    time: Date.now(),
                    sender: user._id,
                },
            });
            form.resetFields();
        } catch (err) {
            message.error('Veuillez remplir le champ description');
        }
    };

    const CardTitle = (
        <Collapse defaultActiveKey={['1']} collapsible="disabled">
            <Panel
                style={{ background: '#fff' }}
                header={(
                    <div className="flex" style={{ alignItems: 'center' }}>
                        <InfoCircleOutlined className="m-right-small" />
                        Informations sur la Query
                    </div>
                )}
                key="1"
            >
                <div className="flex m-bottom-medium">
                    <Text strong>Patient : </Text>
          &nbsp;
                    {getFullNameDynamicF(query.patient)}
                </div>
                <div className="flex m-bottom-medium">
                    <Text strong>Champ : </Text>
          &nbsp;
                    {query.field.label}
                </div>
                <div className="flex m-bottom-medium">
                    <Text strong>Valeur initiale : </Text>
          &nbsp;
                    {query.field.value}
                </div>
            </Panel>
        </Collapse>
    );

    return (
        <Card
            className="query-messages-card"
            title={CardTitle}
            bordered={false}
            actions={[
                <div className="flex-column" style={{ marginRight: 20 }}>
                    <Form onFinish={onFinish} form={form}>
                        <Form.Item name="messageToSend"
                            style={{ marginBottom: 12 }}
                            rules={[FORM_VALIDATORS.required]}
                        >
                            <Input
                                autoFocus
                                style={{ marginBottom: 10 }}
                                placeholder="Tapez votre message ici"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button block type="primary" htmlType="submit">
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Form>
                </div>,
            ]}
        >
            <div style={{ height: '40vh', overflow: 'auto' }} id="messages-container">
                <Messages messages={query ? query.messages : []} />
            </div>
        </Card>
    );
};

QueryMessages.propTypes = {
    query: PropTypes.object,
    pushQueryMessageAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

QueryMessages.defaultProps = {
    query: null,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        pushQueryMessageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(QueryMessages);
