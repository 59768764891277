/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';
import { getFullNameDynamicF } from '../../../helpers/functions';


const AddDescriptionToPatient = ({
    visible, patient, onCancel, onCreate,
}) => {
    const [form] = Form.useForm();


    return (
        <Modal
            visible={visible}
            okText="Ajouter"
            cancelText="Annuler"
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
            title={patient ? `Ajouter une description pour le patient ${getFullNameDynamicF(patient)}` : null}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Form>

        </Modal>
    );
};

AddDescriptionToPatient.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
};


export default AddDescriptionToPatient;
