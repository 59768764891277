import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import PatientsTable from './sections/Patients/patientsTable';
import history from './history';
import Login from './sections/Login';
import Patients from './sections/Patients';
import AdverseEvent from './sections/AdverseEvents';
import IncomingCalls from './sections/IncomingCalls';
import OpenQueries from './sections/Queries/open';
import AnsweredQueries from './sections/Queries/answered';
import ClosedQueries from './sections/Queries/closed';


export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/patients">
                    <Patients />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route exact path="/red">
                    <PatientsTable key="red" />
                </Route>
                <Route exact path="/blue">
                    <PatientsTable key="blue" />
                </Route>
                <Route exact path="/orange">
                    <PatientsTable key="orange" />
                </Route>
                <Route exact path="/adverse">
                    <AdverseEvent />
                </Route>
                <Route exact path="/incoming">
                    <IncomingCalls />
                </Route>
                <Route exact path="/open">
                    <OpenQueries />
                </Route>
                <Route exact path="/answered">
                    <AnsweredQueries />
                </Route>
                <Route exact path="/closed">
                    <ClosedQueries />
                </Route>
            </Switch>
        </Router>
    );
}
